import { useCallback } from 'react';
import clsx from 'clsx';

import styles from './Button.module.scss';

import { useBuyRoach } from '../../../pages/Home/api/roachQuery';
import { useRoachStore } from '../../../store/roachSlice';
import { roachName } from '../../../helpers/names';
import { useStore } from '../../../store/globalStore';

export default function UnlockButton() {
    const roaches = useRoachStore((state) => state.roaches);
    const user = useStore((state) => state.user);

    const { mutate: buyRoach } = useBuyRoach();

    const onBuyRoach = useCallback(() => {
        if (user?.username) {
            const name = roaches?.length ? roachName() : user.username;
            buyRoach({ name });
        }
    }, [user, roaches, buyRoach]);

    const isAvailable =
        user?.balance !== undefined &&
        user?.nextRoachPriceInfo.cost >= 0 &&
        user?.balance.mtgn >= user?.nextRoachPriceInfo.cost;

    const newRoachPrice = user?.nextRoachPriceInfo.cost || 0;

    return (
        <button
            className={clsx(styles.button, !isAvailable && styles.disabled)}
            onClick={onBuyRoach}
            disabled={!isAvailable}
        >
            {newRoachPrice === 0 ? 'Get Roach' : `Unlock: ${newRoachPrice} MGTN`}
        </button>
    );
}
