export enum UserStatus {
    CREATED_BY_MANAGER = 0,
    INTERACT_WITH_BOT = 1,
    LAUNCH_GAME = 2,
    REGISTERED = 3,
    FARMING_START = 4,
    INTERACT_WITH_FARMING = 5,
    CLAIMED_FARM_SESSION = 6,
}

export enum TaskItemStatus {
    AUTO = 'auto',
    CAN_OPEN = 'can_open',
    OPENED = 'opened',
    CAN_CLAIM = 'can_claim',
    CLAIMED = 'claimed',
}
