import { useRoachStore } from '../../../store/roachSlice';
import { useRaceStore } from '../../../store/raceSlice';
import InRaceButton from './InRace';
import ChargeButton from './Charge';
import UnlockButton from './Unlock';
import RaceButton from './Race';

export default function HomeButton(): JSX.Element {
    const roach = useRoachStore((state) => state.currentRoach);
    const inRace = useRaceStore((state) => state.inRace);

    if (roach) {
        if (inRace) {
            return <InRaceButton />;
        } else if (roach.currentEnergy < 50) {
            return <ChargeButton />;
        } else {
            return <RaceButton />;
        }
    } else {
        return <UnlockButton />;
    }
}
