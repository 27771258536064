import ProgressBarLib from '@ramonak/react-progress-bar';
import React from 'react';

type Props = {
    progress: number;
};

export const ProgressBar: React.FC<Props> = ({ progress = 0 }) => {
    return (
        <div className="flex w-full py-1.5 gap-6 items-center">
            <ProgressBarLib
                className="w-72"
                height="8px"
                bgColor="#3EDC03"
                baseBgColor="#2B2B2B"
                completed={progress >= 98 ? progress + 2 : progress}
                customLabel={' '}
                transitionDuration={progress >= 100 ? '100ms' : '700ms'}
            />
            <span className="font-mono text-[15px] w-11 text-[#808080]">
                {progress >= 100 ? 'Done' : `${progress}%`}
            </span>
        </div>
    );
};
