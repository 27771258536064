import styles from './index.module.scss';

interface Props {
    onClick(): void;
}
const RollRoachButton = ({ onClick }: Props) => {
    return (
        <button onClick={onClick} className={styles.button}>
            Generate
        </button>
    );
};

export { RollRoachButton };
