import { postEvent, SDKProvider, useHapticFeedback, useUtils } from '@telegram-apps/sdk-react';
import { ReactNode } from 'react';
import { isDebugMode } from './config';

type THapticImpact = 'light' | 'medium' | 'heavy' | 'rigid' | 'soft';

interface SDKWrapperProps {
    children: ReactNode;
}
const SDKWrapper: React.FC<SDKWrapperProps> = ({ children }) => {
    if (isDebugMode()) {
        return <>{children}</>;
    }
    return <SDKProvider acceptCustomStyles>{children}</SDKProvider>;
};

interface UseSDKWrapperReturn {
    appExpand: () => void;
    verticalSwipeDisabled: () => void;
    openLink: (url: string) => void;
    hapticImpact: (type: THapticImpact) => void;
}
const useSDKWrapper = (): UseSDKWrapperReturn => {
    // eslint-disable-next-line
    const utils = isDebugMode() ? undefined : useUtils();
    // eslint-disable-next-line
    const haptic = isDebugMode() ? undefined : useHapticFeedback();

    const appExpand = () => {
        if (!isDebugMode()) {
            return postEvent('web_app_expand');
        }
    };

    const verticalSwipeDisabled = () => {
        if (!isDebugMode()) {
            return postEvent('web_app_setup_swipe_behavior', { allow_vertical_swipe: false });
        }
    };

    const openLink = (url: string) => {
        if (isDebugMode()) {
            window.open(url, '_blank');
        } else {
            if (url.includes('https://t.me/')) {
                utils?.openTelegramLink(url);
            } else {
                utils?.openLink(url);
            }
        }
    };

    const hapticImpact = (type: THapticImpact) => {
        setTimeout(() => {
            try {
                if (haptic) {
                    haptic.impactOccurred(type);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }, 0);
    };

    return { appExpand, verticalSwipeDisabled, openLink, hapticImpact };
};

export { useSDKWrapper, SDKWrapper };
