import { FC, HTMLAttributes } from 'react';
import styles from './RoachName.module.scss';
import clsx from 'clsx';

interface RoachNameProps extends HTMLAttributes<HTMLDivElement> {
    name: string;
    coin: string;
}

const RoachName: FC<RoachNameProps> = ({ name, coin, className }) => {
    const coinSrc =
        coin.toLowerCase() === 'wif'
            ? `/assets/coins/${coin.toLowerCase()}.webp`
            : `/assets/coins/${coin.toLowerCase()}.svg`;
    return (
        <div className={clsx(styles.roachName, className)}>
            <img src={coinSrc} alt={coin} className={styles.coin} />
            <span className={styles.name}>{name}</span>
        </div>
    );
};

export { RoachName };
