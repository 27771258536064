import { useCallback, useEffect } from 'react';
import clsx from 'clsx';

import styles from './Button.module.scss';

import { Countdown } from '../../../pages/main/AccountScreen/Home/Countdown';
import { useRoachStore } from '../../../store/roachSlice';
import { useStore } from '../../../store/globalStore';

export default function ChargeButton() {
    const { currentRoach: roach, startCharging, stopCharging } = useRoachStore();
    const config = useStore((state) => state.config);

    useEffect(() => {
        if (roach && roach.currentEnergy < 100 && roach.chargeSession?.chargedEnergy === 100) {
            stopCharging(roach.id);
        }
    }, [roach, stopCharging]);

    const onStartCharge = useCallback(() => {
        if (roach) {
            startCharging(roach.id);
        }
    }, [roach, startCharging]);

    const onCountdownFinished = useCallback(() => {
        if (roach) {
            stopCharging(roach.id);
        }
    }, [roach, stopCharging]);

    const endTime = roach?.chargeSession?.completionTime;
    const isChargingFinished = endTime && new Date(endTime).getTime() < new Date().getTime();
    const isCharging = roach?.status === 'charging';

    return isCharging ? (
        <button className={clsx(styles.button, styles.progress)} disabled>
            {isChargingFinished ? 'Charged' : 'Charging '}

            {endTime && !isChargingFinished && (
                <Countdown
                    serverTimeDiffMs={config.serverTimeDiffMs}
                    onFinished={onCountdownFinished}
                    className={styles.countdown}
                    endTime={endTime}
                />
            )}
        </button>
    ) : (
        <button className={styles.button} onClick={onStartCharge}>
            Charge
        </button>
    );
}
