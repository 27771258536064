import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import styles from './Button.module.scss';
import clsx from 'clsx';

interface ButtonProps
    extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}
const UpgradeButton: FC<ButtonProps> = ({ className, ...props }) => {
    return (
        <button {...props} className={clsx(styles.button, className)}>
            <span className={styles.icon}>🚀</span>Upgrade
        </button>
    );
};

export default UpgradeButton;
