import { useCallback, useEffect, useState } from 'react';
import { ChargingStats } from '../../../modules/ChargingStats';
import { HomeButton } from '../../../modules/HomeButton';
import { Status } from '../../../modules/StatusBar';
import styles from './Home.module.scss';
import { useStore } from '../../../store/globalStore';
import { useRollRoach } from '../api/roachQuery';
import { useRoachStore } from '../../../store/roachSlice';
import { Loader } from '../../../components/Loader/Loader';
import { RoachName } from '../../../components/RoachName/RoachName';
import { useRaceStore } from '../../../store/raceSlice';
import { RaceResults } from '../../../modules/RaceResults';
import { RollRoachButton } from '../../../components/RollRoach';
import Modal from '../../../components/Modal/Modal';
import Upgrade from '../../../components/Upgrade/Upgrade';
import { UpgradeButton } from '../../../modules/Upgrade';
import { BrowseRoaches } from '../../../components/BrowseRoaches';
import { RoachInfoDto, torApi } from '../../../api/tor-api';

const Home = () => {
    const [isLoadingScene, setIsLoadingScene] = useState(true);
    const [isShowUpgrade, setIsShowUpgrade] = useState(false);

    const setIsShowIntro = useStore((state) => state.setIsShowIntro);
    const checkCurrentRace = useRaceStore((state) => state.checkCurrentRace);
    const isShowResult = useRaceStore((state) => state.isShowResult);

    const { roaches, currentRoach, setCurrentRoach, setRoaches } = useRoachStore();
    const { mutate: reRollRoach } = useRollRoach();

    useEffect(() => {
        checkCurrentRace();

        torApi.api.getApiV1Roaches({ page: 1 }).then(({ data }) => {
            if (data.result) {
                setRoaches(data.result);
            }
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setIsShowIntro(false);
    }, [setIsShowIntro]);

    const onChangeRoach = useCallback((roach: RoachInfoDto | undefined, _idx: number) => {
        setCurrentRoach(roach || null);
        // eslint-disable-next-line
    }, []);

    const onLoadedScene = useCallback((initialRoach?: RoachInfoDto) => {
        setCurrentRoach(initialRoach || null);
        setIsLoadingScene(false);
        // eslint-disable-next-line
    }, []);

    return (
        <main className={styles.container}>
            <Status />

            {roaches && (
                <BrowseRoaches
                    sceneClassName={styles['model-wrapper']}
                    className={styles.roachContainer}
                    onChange={onChangeRoach}
                    onLoaded={onLoadedScene}
                    roaches={roaches}
                />
            )}

            <div className={styles.bottom}>
                {currentRoach && (
                    <>
                        <RoachName
                            className={styles.roachName}
                            name={currentRoach.name}
                            coin={currentRoach.coin}
                        />

                        <div className={styles.rowContainer}>
                            <ChargingStats
                                currentEnergy={currentRoach?.currentEnergy}
                                energyGenerationSpeed={currentRoach?.energyGenerationSpeed}
                            />

                            <RollRoachButton
                                onClick={() => reRollRoach(currentRoach.id.toString())}
                            />
                        </div>

                        <UpgradeButton
                            className={styles.upgrade}
                            onClick={() => setIsShowUpgrade(true)}
                        />
                    </>
                )}

                <div className={styles.buttonContainer}>
                    {roaches && !isLoadingScene && <HomeButton />}
                </div>
            </div>
            {isLoadingScene && (
                <div className={styles.loader}>
                    <Loader />
                </div>
            )}
            {isShowResult && <RaceResults />}
            {isShowUpgrade && (
                <Modal onClose={() => setIsShowUpgrade(false)}>
                    <Upgrade />
                </Modal>
            )}
        </main>
    );
};

export default Home;
