import { useRef, useEffect, useCallback, useState } from 'react';

import { RoachInfoDto } from '../../api/tor-api';
import { OnChangeRoach, RoachesScene } from './Scene';

interface BrowseRoachesProps {
    onLoaded?: (roach?: RoachInfoDto) => void;
    onChange?: OnChangeRoach;

    roaches: RoachInfoDto[];

    sceneClassName?: string;
    className?: string;
}

const isTouch = 'ontouchstart' in document.documentElement;
const SWIPE_TRIGGER_OFFSET = 0.4
const CUTOFF_SIZE = 150;

export function BrowseRoaches({
    sceneClassName,
    className,
    onChange,
    onLoaded,
    roaches,
}: BrowseRoachesProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const sceneRef = useRef<RoachesScene>();

    const [containerWidth, setContainerWidth] = useState(0);
    const [isMove, setIsMove] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [startX, setStartX] = useState(0);

    useEffect(() => {
        const onResize = (entry: ResizeObserverEntry) => setContainerWidth(entry.contentRect.width);
        const resizeObserver = new ResizeObserver((elements) => elements.forEach(onResize));

        if (containerRef.current) {
            const scene = new RoachesScene(containerRef.current, sceneClassName, false);
            resizeObserver.observe(containerRef.current);

            scene.initModels().then(() => {
                const tasks = scene.initGallery(roaches);

                if (scene.gallery) {
                    scene.gallery.onChange = onChange;
                    onLoaded?.(scene.gallery.getCurrent()?.roach);
                }

                return tasks;
            });

            sceneRef.current = scene;
        }

        return () => {
            // eslint-disable-next-line
            if (containerRef.current) resizeObserver.unobserve(containerRef.current);
            sceneRef.current?.destroy();
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        sceneRef.current?.gallery?.set(roaches);
    }, [roaches]);

    useEffect(() => {
        if (sceneRef.current?.gallery) {
            sceneRef.current.gallery.onChange = onChange;
        }
    }, [onChange]);

    const onStart = useCallback(
        (touchStartX: number) => {
            setIsMove(true);
            setStartX(touchStartX);
            setOffsetX(0);
        },
        // eslint-disable-next-line
        [isMove, startX],
    );

    const onMove = useCallback(
        (touchCurrentX: number) => {
            const offset = touchCurrentX - startX;
            const offsetPercent = offset / containerWidth;
            
            if (isMove) {
                sceneRef.current?.gallery?.offsetTo(offsetPercent);
            }

            setOffsetX(offsetPercent);
        },
        // eslint-disable-next-line
        [isMove, startX, containerWidth],
    );

    const onEnd = useCallback(() => {
        setIsMove(false);
        setOffsetX(0);

        if (offsetX > SWIPE_TRIGGER_OFFSET) {
            sceneRef.current?.gallery?.moveToPrev();
        } else if (offsetX < -SWIPE_TRIGGER_OFFSET) {
            sceneRef.current?.gallery?.moveToNext();
        } else {
            sceneRef.current?.gallery?.moveToCurrent();
        }

        // eslint-disable-next-line
    }, [isMove, offsetX, startX, containerWidth]);

    const onTouchStart = useCallback(
        (e: React.TouchEvent<HTMLDivElement>) => {
            const touchStartX = e.touches[0].clientX;
            onStart(touchStartX);
        },

        // eslint-disable-next-line
        [isMove, startX],
    );

    const onTouchMove = useCallback(
        (e: React.TouchEvent<HTMLDivElement>) => {
            const touchCurrentX = e.touches[0].clientX;
            onMove(touchCurrentX);
        },

        // eslint-disable-next-line
        [isMove, startX],
    );

    const onMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            const touchStartX = e.nativeEvent.offsetX;
            onStart(touchStartX);
        },

        // eslint-disable-next-line
        [isMove, startX],
    );

    const onMouseMove = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            const touchCurrentX = e.nativeEvent.offsetX;
            onMove(touchCurrentX);
        },

        // eslint-disable-next-line
        [isMove, startX, containerWidth],
    );

    const onClick: React.MouseEventHandler<HTMLDivElement> = useCallback((event) => {
        const { clientWidth: totalWidth } = containerRef.current as HTMLDivElement;
        const { offsetX: x } = event.nativeEvent;

        if (x < CUTOFF_SIZE) {
            sceneRef.current?.gallery?.moveToPrev();
        } else if (x > totalWidth - CUTOFF_SIZE) {
            sceneRef.current?.gallery?.moveToNext();
        }

        // eslint-disable-next-line
    }, []);

    return (
        <div
            id="containerRef"
            onTouchStart={isTouch ? onTouchStart : undefined}
            onTouchMove={isTouch ? onTouchMove : undefined}
            onTouchEnd={isTouch ? onEnd : undefined}
            onMouseDown={!isTouch ? onMouseDown : undefined}
            onMouseMove={!isTouch ? onMouseMove : undefined}
            onMouseUp={!isTouch ? onEnd : undefined}
            className={className}
            onClick={onClick}
            ref={containerRef}
        />
    );
}
