import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { AppRoot } from '../../App';
import { UserRoot } from '../layouts/userRoot';
import { Race } from '../../pages/main/AccountScreen/Race';
import RaceTor from '../../pages/main/AccountScreen/Race/Race';
import { Roach } from '../../pages/Home';
import { Friends } from '../../pages/main/AccountScreen/Friends';
import { Tasks } from '../../pages/main/AccountScreen/Tasks';
import { FirstScreen } from '../../pages/main/FirstScreen';
import { CreateAccountScreen } from '../../pages/main/CreateAccountScreen';
import { isTor } from '../../helpers/config';
import { Home } from '../../pages/main/AccountScreen/Home';
import { DailyRewards } from '../../modules/DailyRewards';

const homePage = isTor() ? <Roach /> : <Home />;
const racePage = isTor() ? <RaceTor /> : <Race />;

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path="/" element={<AppRoot />}>
                <Route path="/" element={<UserRoot />}>
                    <Route path="home" element={homePage} />
                    <Route path="friends" element={<Friends />} />
                    <Route path="tasks" element={<Tasks />} />
                    <Route path="race" element={racePage} />
                </Route>

                <Route path="/start" element={<FirstScreen />} />
                <Route path="/create" element={<CreateAccountScreen />} />
                <Route path="/rewards" element={<DailyRewards />} />
            </Route>
        </Route>,
    ),
);
