import { Tween, Group as TweenGroup } from '@tweenjs/tween.js';
import * as three from 'three';

import { CAMERA_X, ModelsCache } from './Scene';
import { RoachInfoDto } from '../../../api/tor-api';
import { RoachActor, X_OFFSET } from './RoachActor';

export type OnChangeRoach = (roach: RoachInfoDto | undefined, idx: number) => void;

export const EMPTY_ROACH_ID = -1;
const MOVE_DURATION = 300;

export class GalleryScene {
    private camera: three.PerspectiveCamera;
    private models: ModelsCache;
    private scene: three.Scene;
    private tweens: TweenGroup;

    public onChange?: OnChangeRoach;

    private roaches: Map<number, RoachActor> = new Map();
    private ids: number[] = [];

    private current = {
        id: EMPTY_ROACH_ID,
        idx: 0,
    };

    constructor(
        camera: three.PerspectiveCamera,
        models: ModelsCache,
        tweens: TweenGroup,
        scene: three.Scene,
    ) {
        this.tweens = tweens;
        this.models = models;
        this.camera = camera;
        this.scene = scene;
    }

    public getCurrent = () => this.roaches.get(this.ids[this.current.idx]);

    public set = async (roaches: RoachInfoDto[]) => {
        const tasks: Array<Promise<void>> = [];
        const newest = [];

        for (const roach of roaches) {
            const founded = this.roaches.get(roach.id);

            if (founded) {
                const setterTask = founded.set(roach, this.models);
                tasks.push(setterTask);
            } else {
                newest.push(roach);
            }
        }

        if (newest.length > 0) {
            const [first, ...tail] = newest;

            {
                const emptyToFill = this.roaches.get(EMPTY_ROACH_ID) as RoachActor;
                this.roaches.delete(EMPTY_ROACH_ID);
    
                tasks.push(emptyToFill.set(first, this.models));
                this.ids.splice(this.ids.length - 1, 0, first.id);

                this.roaches.set(first.id, emptyToFill);

                if (emptyToFill.idx === this.current.idx) {
                    this.current.id = first.id
                    this.onChange?.(first, emptyToFill.idx);
                }
            }

            for (const roach of tail) {
                const actor = new RoachActor(this.ids.length - 1, this.models, roach);

                this.roaches.set(roach.id, actor);
                this.ids.splice(this.ids.length - 1, 0, roach.id);

                tasks.push(actor.setup(this.models));
                this.scene.add(actor.scene);
            }

            const newEmpty = new RoachActor(this.ids.length - 1, this.models);
            this.roaches.set(EMPTY_ROACH_ID, newEmpty);
            this.scene.add(newEmpty.scene);

            tasks.push(newEmpty.setup(this.models));
        }
        
        await Promise.all(tasks);
    };

    public setup = async (initialRoaches?: RoachInfoDto[]) => {
        let tasks: Array<Promise<void>> = [];

        if (initialRoaches && initialRoaches.length > 0) {
            const [first] = initialRoaches;
            this.current.id = first.id;

            tasks = initialRoaches.reduce(
                (tasks, roach, idx) => {
                    const actor = new RoachActor(idx, this.models, roach);

                    this.roaches.set(roach.id, actor);
                    this.ids.push(roach.id);

                    tasks.push(actor.setup(this.models));
                    this.scene.add(actor.scene);

                    return tasks;
                },
                [] as typeof tasks,
            );
        }

        {
            const empty = new RoachActor(this.ids.length, this.models);

            this.roaches.set(EMPTY_ROACH_ID, empty);
            this.ids.push(EMPTY_ROACH_ID);
            this.scene.add(empty.scene);

            tasks.push(empty.setup(this.models));
        }

        this.roaches.get(this.ids[0])?.animation().start()

        tasks[0].finally(() => {
            const first = this.roaches.get(this.ids[0]);
            first?.animation().start();
        });

        return Promise.all(tasks);
    };

    // offsetX: percent
    public offsetTo = (offsetX: number) => {
        const current = this.getCurrent()
        
        if (current) {
            const currentX = current.scene.position.x
            const offsetCameraX = X_OFFSET * +offsetX
            const nextX = currentX - offsetCameraX

            this.camera.position.setX(nextX)
        }
    }

    public moveToCurrent = (duration = MOVE_DURATION) => {
        this.moveToId(this.current.id, duration)
    }

    public moveToId = (id: number, duration = MOVE_DURATION) => {
        const roach = this.roaches.get(id);

        if (roach) {
            const current = this.getCurrent();

            current?.animation().stop();
            roach.animation().start();

            const nextX = roach.scene.position.x + CAMERA_X;

            const tween = new Tween({ x: this.camera.position.x })
                .to({ x: nextX }, duration)
                .onUpdate(({ x }) => this.camera.position.setX(x))
                .start();

            this.current = {
                idx: roach.idx,
                id: roach.id,
            };

            this.onChange?.(roach.roach, roach.idx);

            tween.onComplete(() => this.tweens.remove(tween));
            this.tweens.add(tween);
        }
    };

    public moveToIdx = (idx: number, duration = MOVE_DURATION) => {
        const roach = idx >= 0 && idx < this.ids.length && this.roaches.get(this.ids[idx]);
        if (roach && this.ids.length > 1) this.moveToId(roach.id, duration);
    };

    public moveToNext = () => {
        let nextIdx = this.current.idx + 1;
        if(nextIdx < this.ids.length) this.moveToIdx(nextIdx);
    };

    public moveToPrev = () => {
        let prevIdx = this.current.idx - 1;
        if (prevIdx >= 0) this.moveToIdx(prevIdx);
    };

    public tick = (deltaTime: number) => {
        this.roaches.forEach((actor) => actor.animation().tick(deltaTime));
    };
}
