import { FC, useRef } from 'react';
import clsx from 'clsx';
import styles from './Reward.module.scss';
import { DailyRewardItemDto } from '../../../../api/tor-api';
import Button from '../../../../components/Button/Button';
import { ReactComponent as Coin } from '../../../../assets/mtgn.svg';
import { ReactComponent as Tick } from '../../../../assets/tick.svg';
import { useClaimTask } from '../../api/tasksQuery';

interface RewardProps {
    reward: DailyRewardItemDto;
}
const Reward: FC<RewardProps> = ({ reward }) => {
    const { mutate: claim, isPending } = useClaimTask();
    const audioClaim = useRef(new Audio('/music/claim.mp3'));

    const onClaim = () => {
        claim({ taskId: reward.id });
        audioClaim?.current?.play?.();
    };
    return (
        <div
            className={clsx(
                styles.wrapper,
                reward.status === 'can_claim' && styles.current,
                reward.status === 'auto' && styles.disabled,
            )}
        >
            <span className={styles.day}>Day {reward.day}</span>
            <div className={styles.rewardContainer}>
                <Coin />
                <span>{reward.reward}</span>
            </div>
            <div className={styles.bottom}>
                {reward.status === 'claimed' ? (
                    <div className={styles.badgeClaimed}>
                        <Tick />
                        <span>Claimed</span>
                    </div>
                ) : (
                    <Button onClick={onClaim} disabled={isPending} className={styles.buttonClaim}>
                        Claim
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Reward;
