import { useState } from 'react';
import { torApi } from '../../../../api/tor-api';
import { useHandleError } from '../../../../hooks/useHandleError';
import { useStore } from '../../../../store/globalStore';
import styles from './CreateAccountButton.module.scss';
import { useNavigate } from 'react-router-dom';

enum Screen {
    FIRST = 'FIRST',
    CREATE = 'CREATE',
    ACCOUNT = 'ACCOUNT',
}

export const CreateButton: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const mergeUser = useStore((state) => state.mergeUser);
    const updateScreen = useStore((state) => state.updateScreen);
    const { handleError } = useHandleError();
    const navigate = useNavigate();

    const onCreateAccount = () => {
        console.log('Main.onCreateAccount');
        updateScreen(Screen.CREATE);
        setIsLoading(true);
        torApi.api
            .postApiV1UserCreate()
            .then((resp) => {
                if (resp.status !== 200) throw new Error('User not defined');
                setIsLoading(false);
                navigate('/create');
                return resp.data;
            })
            .then(mergeUser)
            .catch(handleError)
            .finally(() => setIsLoading(false));
    };
    return (
        <button onClick={onCreateAccount} className={styles.button} disabled={isLoading}>
            Create account
        </button>
    );
};
