import {
    memo,
    MouseEventHandler,
    TouchEventHandler,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { AudioKind, EggView, TARGET_BULB_COUNT } from '../../Egg/EggView';
import {
    canStartFarmingSession,
    isFarmingSessionActive,
    isFarmingSessionFinished,
} from '../../../../helpers/utils';
import { EggState } from '../../Egg/EggModel';
import EventEmitter, { EVENTS } from '../../../../helpers/eventemitter';
import { SvgCircle } from './SvgCircle';
import ProgressBar from '@ramonak/react-progress-bar';
import { SvgGlass } from './SvgGlass';
import clsx from 'clsx';
import { useHandleError } from '../../../../hooks/useHandleError';
import { useSDKWrapper } from '../../../../helpers/sdkWrapper';
import { useStore } from '../../../../store/globalStore';
import { torApi } from '../../../../api/tor-api';
import { makeTapRaw } from '../../../../helpers/api';
import { IntroOverlay } from './IntroOverlay/IntroOverlay';
import FarmingProgress from './FarmingProgress/FarmingProgress';
import { ReactComponent as Coin } from '../../../../assets/mtgn.svg';
import { useFarming } from '../../../../store/farmingSlice';
import styles from './index.module.scss';
import { useTapCount } from '../../../../store/tapCountSlice';
import { Loader } from '../../../../components/Loader/Loader';

export enum HomeTouchEnum {
    top = 'TOP',
    left = 'LEFT',
    bottom = 'BOTTOM',
    right = 'RIGHT',
}

enum Screen {
    FarmingPopup,
    Clicker,
    Undefined,
}

let touches = false;

export const Home: React.FC = memo(() => {
    const [state, _setState] = useState<EggState>(EggState.NONE);
    const { tapCount, increaseTapCount, resetTapCount } = useTapCount();
    const circleContainerRef = useRef<HTMLDivElement>(null);
    const [isLoadingClaim, setIsLoadingClaim] = useState(false);
    const { handleError } = useHandleError();
    const { hapticImpact } = useSDKWrapper();
    const userBalance = useStore((state) => state.user?.balance.mtgn);
    const { user, mergeUser, config, isShowIntro, setIsShowIntro, addBalance } = useStore();
    const setSessionFarmedCoins = useFarming((state) => state.setSessionFarmedCoins);
    const [isFarming, setIsFarming] = useState<boolean>(true);

    const containerRef = useRef<HTMLDivElement>(null);
    const eggView = useRef<EggView | null>(null);
    const fieldRef = useRef<HTMLDivElement>(null);
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const [isActiveTouch, setIsActiveTouch] = useState(false);
    const [touchOrientation, setTouchOrientation] = useState<HomeTouchEnum>(HomeTouchEnum.top);
    const [screen, setScreen] = useState<Screen>(Screen.Undefined);
    const [isLoadingFarm, setIsLoadingFarm] = useState<boolean>(false);

    const tapPoints = config.config ? (1 / TARGET_BULB_COUNT) * config.config.TAP_REWARD : 0.01;

    const onClickEgg = () => {
        setIsShowIntro(false);

        if (
            !(
                isFarmingSessionActive(user, config?.serverTimeDiffMs) ||
                isFarmingSessionFinished(user, config?.serverTimeDiffMs)
            )
        ) {
            hapticImpact('light');
        }
    };

    function setState(newState: EggState) {
        _setState(newState);
        eggView.current?.setState(newState);
    }

    function setTapCount(newState: number) {
        if (newState) {
            increaseTapCount();
        } else resetTapCount();
        eggView.current?.setTapCount(newState);
    }

    function onDown() {
        eggView.current?.playMusic();

        let fixedState;
        if (state === EggState.NONE) {
            fixedState = !user || !user.farmSessionInfo ? EggState.GROWTH : EggState.HATCHING;
        } else {
            fixedState = state;
        }
        if (state !== fixedState) {
            setState(fixedState);
        }

        const tapSound = fixedState === EggState.HATCHING ? AudioKind.TAP : AudioKind.TAP_FARMING;
        console.log('state', state, tapSound);
        eggView.current?.playSound(tapSound);

        if (!user || !user.farmSessionInfo) {
            switch (fixedState) {
                case EggState.NONE:
                    setState(EggState.GROWTH);
                    break;
                case EggState.GROWTH:
                    if (tapCount + 1 >= TARGET_BULB_COUNT) {
                        setTapCount(tapCount + 1);
                        setState(EggState.HATCHING);
                        setIsLoadingFarm(true);
                        makeTapRaw(1)
                            .then((userUpdates) => {
                                if (userUpdates && userUpdates !== true) {
                                    // eggModel.rotationSpeed = TAP_DENIED_ROTATION_SPEED;
                                    mergeUser(userUpdates);
                                    setIsLoadingFarm(false);
                                    console.log(
                                        `after tap = ${user?.farmSessionInfo?.lastFarmedCoins} farmedCoins=${user?.farmSessionInfo?.farmedCoins}`,
                                    );
                                }
                            })
                            .catch(handleError)
                            .finally(() => setIsLoadingFarm(false));
                        eggView.current?.playSound(AudioKind.START_FARMING);
                    } else {
                        setTapCount(tapCount + 1);

                        // update balance
                        if (config?.config) {
                            addBalance(tapPoints);
                        }
                    }
                    break;
                case EggState.HATCHING:
                    // just claimed
                    break;
                case EggState.DISAPPEAR:
                    setState(EggState.GROWTH);
                    break;
                default:
                    break;
            }
        } else {
            switch (fixedState) {
                case EggState.NONE:
                    setState(EggState.HATCHING);
                    break;
            }
        }
    }

    function handleClaimClick() {
        setIsLoadingClaim(true);
        hapticImpact('medium');
        eggView.current?.playSound(AudioKind.CLAIM);
        return torApi.api
            .postApiV1FarmClaim()
            .then((userUpdates) => {
                if (userUpdates.data) {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    mergeUser(userUpdates.data);
                    // if (eggView.current) {
                    //     eggView.current.onClaimed(user);
                    // }
                    setState(EggState.GROWTH);
                }
            })
            .catch(handleError)
            .finally(() => {
                setScreen(Screen.Clicker);
                setIsLoadingClaim(false);
                setIsFarming(true);
            });
    }

    const onMouseMove: MouseEventHandler<HTMLDivElement> = (e: any) => {
        if (!touches) {
            EventEmitter.emit(EVENTS.MOVE, e);
        }
    };
    const onTouchMove: TouchEventHandler<HTMLDivElement> = (e: any) => {
        EventEmitter.emit(EVENTS.MOVE, {
            pageX: e.touches[0].clientX,
            pageY: e.touches[0].clientY,
        });
    };
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e: any) => {
        // ignore mouse event of there was one touch event
        if (!touches) {
            console.log('onMouseDown');
            onDown();
            EventEmitter.emit(EVENTS.DOWN, e);
        }
    };
    const onTouchDown: TouchEventHandler<HTMLDivElement> = (e: any) => {
        touches = true;
        console.log('onTouchDown');
        onDown();

        EventEmitter.emit(EVENTS.DOWN, {
            pageX: e.touches[0].clientX,
            pageY: e.touches[0].clientY,
            buttons: 1,
        });
    };
    const onMouseUp: MouseEventHandler<HTMLDivElement> = (e: any) => {
        if (!touches) {
            EventEmitter.emit(EVENTS.UP, e);
        }
    };
    const onTouchEnd: TouchEventHandler<HTMLDivElement> = () => {
        EventEmitter.emit(EVENTS.UP, {});
    };

    const onTouchStartCircle: TouchEventHandler<HTMLDivElement> = (e) => {
        const elemBounding = circleContainerRef?.current?.getBoundingClientRect();

        if (elemBounding) {
            const elementLeftEdge = elemBounding.left;
            const elementTopEdge = elemBounding.top;
            const elementRightEdge = elemBounding.right;
            const elementBottomEdge = elemBounding.bottom;

            const mouseX = e.touches[0].pageX;
            const mouseY = e.touches[0].pageY;

            const topEdgeDist = Math.abs(elementTopEdge - mouseY);
            const bottomEdgeDist = Math.abs(elementBottomEdge - mouseY);
            const leftEdgeDist = Math.abs(elementLeftEdge - mouseX);
            const rightEdgeDist = Math.abs(elementRightEdge - mouseX);

            const min = Math.min(topEdgeDist, bottomEdgeDist, leftEdgeDist, rightEdgeDist);

            if (min === leftEdgeDist) {
                setTouchOrientation(HomeTouchEnum.left);
            } else if (min === bottomEdgeDist) {
                setTouchOrientation(HomeTouchEnum.bottom);
            } else if (min === rightEdgeDist) {
                setTouchOrientation(HomeTouchEnum.right);
            } else if (min === topEdgeDist) {
                setTouchOrientation(HomeTouchEnum.top);
            }
        }

        setIsActiveTouch(true);
    };

    useEffect(() => {
        const intervalFn = () => {
            if (eggView.current && user && user.farmSessionInfo && config) {
                const metrics = eggView.current.calcSessionMetrics(user.farmSessionInfo);
                const farmedCoins = metrics.currentFarmedCoins;
                const formatedSessionFarmedCoins = farmedCoins.toFixed(2);
                setSessionFarmedCoins(formatedSessionFarmedCoins);
                setTapCount(
                    isFarmingSessionFinished(user, config?.serverTimeDiffMs) ? 0 : tapCount,
                );
            }
        };
        intervalRef.current = setInterval(intervalFn, 500);
        intervalFn();
        return () => {
            intervalRef.current && clearInterval(intervalRef.current);
        };
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if (containerRef.current && eggView.current === null) {
            eggView.current = new EggView(containerRef.current);
        }

        return () => {
            if (eggView.current) {
                eggView.current.destroy();
                eggView.current = null;
            }
        };
    }, [containerRef]);

    useEffect(() => {
        if (eggView.current && config) {
            eggView.current.setConfig(config);
        }
    }, [config]);

    useEffect(() => {
        if (eggView.current && user) {
            eggView.current.setUser(user);
            let fixedState;
            if (state === EggState.NONE) {
                fixedState = !user || !user.farmSessionInfo ? EggState.GROWTH : EggState.HATCHING;
            } else {
                fixedState = state;
            }
            eggView.current.setState(fixedState);
        }
    }, [user, state]);

    // useEffect(() => {
    //     if (tapProgress >= 100) {
    //         if (audioFarming) {
    //             audioFarming.current.currentTime = 0;
    //             audioFarming.current.play?.();
    //         }
    //     }
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [tapProgress]);

    useEffect(() => {
        if (
            isFarmingSessionActive(user, config?.serverTimeDiffMs) ||
            isFarmingSessionFinished(user, config?.serverTimeDiffMs)
        ) {
            setScreen(Screen.FarmingPopup);
        } else {
            setScreen(Screen.Clicker);
        }
    }, [config, user]);

    const progressBar = () => {
        if (canStartFarmingSession(user)) {
            const tapProgress = (tapCount / TARGET_BULB_COUNT) * 100;
            return (
                <div className="py-3 flex flex-col w-full items-center justify-center font-mono h-auto font-semibold relative overflow-hidden">
                    <ProgressBar
                        className="w-full left-0"
                        width="100%"
                        height="8px"
                        bgColor="#3EDC03"
                        baseBgColor="#2B2B2B"
                        borderRadius="4px"
                        customLabel={' '}
                        completed={tapProgress}
                        transitionDuration="0.2s"
                    />
                    <span className="z-[2] w-full text-xs mt-2">
                        {`${tapCount}/${TARGET_BULB_COUNT} taps to start farming`}
                    </span>
                </div>
            );
        }
    };

    const renderCanvas = useMemo(() => {
        return (
            <div
                className="flex w-full justify-center items-center h-full z-[2]"
                ref={containerRef}
            />
        );
    }, []);

    return (
        <div
            className="flex h-full flex-col w-full justify-between relative items-center"
            ref={fieldRef}
        >
            {isShowIntro && <IntroOverlay />}

            <FarmingProgress
                visible={screen === Screen.FarmingPopup}
                isLoadingClaim={isLoadingClaim}
                onClaim={handleClaimClick}
                isFarming={isFarming}
                onCountdownFinished={() => setIsFarming(false)}
            />

            <div className="w-auto pt-3 gap-2 items-center justify-center flex z-[80]">
                <Coin />
                <span className="font-druk font-bold text-[1.5rem] tracking-[0.06rem] capitalize text-white">
                    {userBalance ? parseFloat(String(userBalance))?.toFixed(2) : 0.0}
                </span>
            </div>

            <div
                onClick={(e) => {
                    if (fieldRef.current && !isLoadingFarm) {
                        const newElement = document.createElement('span');
                        newElement.textContent = `+${tapPoints.toFixed(2)}`;
                        newElement.className = styles.animated;
                        fieldRef.current.appendChild(newElement);

                        const { clientX, clientY } = e;
                        const { style } = newElement;

                        style.setProperty('--effect-top', `${clientY}px`);
                        style.setProperty('--effect-left', `${clientX}px`);

                        setTimeout(() => {
                            if (fieldRef.current) {
                                fieldRef.current.removeChild(newElement);
                            }
                        }, 3000);
                    }
                    onClickEgg();
                }}
                onMouseDown={onMouseDown}
                onTouchStart={onTouchDown}
                onMouseMove={onMouseMove}
                onTouchMove={onTouchMove}
                onMouseUp={onMouseUp}
                onTouchEnd={onTouchEnd}
                onTouchCancel={onTouchEnd}
                // onMouseLeave={onUpContainer}
                className={clsx(
                    'w-full flex overflow-hidden flex-grow absolute bottom-0 top-0 select-none items-center justify-center',
                    {
                        [styles.layer]: true,
                        [styles.active]: screen === Screen.Clicker,
                    },
                )}
            >
                <div
                    ref={circleContainerRef}
                    onTouchStart={onTouchStartCircle}
                    onTouchEnd={() => setIsActiveTouch(false)}
                    onMouseUp={() => setIsActiveTouch(false)}
                    className={clsx(
                        'w-full max-w-[328px] max-h-[328px] h-full flex relative items-center justify-center rounded-[100%] transition-all duration-300 ease-out-quint',
                        {
                            [styles.shadowTop]:
                                isActiveTouch && touchOrientation === HomeTouchEnum.top,
                            [styles.shadowBottom]:
                                isActiveTouch && touchOrientation === HomeTouchEnum.bottom,
                            [styles.shadowRight]:
                                isActiveTouch && touchOrientation === HomeTouchEnum.right,
                            [styles.shadowLeft]:
                                isActiveTouch && touchOrientation === HomeTouchEnum.left,
                        },
                    )}
                >
                    <div className="h-full w-full relative z-[2] rounded-[100%] overflow-hidden">
                        {renderCanvas}
                    </div>

                    <div className="flex items-center justify-center absolute z-[3]">
                        <SvgCircle isPremium={!!user?.isPremium} />
                    </div>

                    <div className="absolute pointer-events-none z-[4]">
                        <SvgGlass />
                    </div>

                    <div className="w-full h-full rounded-full overflow-hidden absolute z-[6]">
                        {isActiveTouch &&
                            !(
                                isFarmingSessionActive(user, config?.serverTimeDiffMs) ||
                                isFarmingSessionFinished(user, config?.serverTimeDiffMs)
                            ) && (
                                <img
                                    alt="light"
                                    className={clsx('absolute pointer-events-none', {
                                        'top-[-13%]': touchOrientation === HomeTouchEnum.top,
                                        'left-[-32%] top-[20%] rotate-[-90deg]':
                                            touchOrientation === HomeTouchEnum.left,
                                        'bottom-[-13%] rotate-[-180deg]':
                                            touchOrientation === HomeTouchEnum.bottom,
                                        'right-[-32%] top-[15%] rotate-[-270deg]':
                                            touchOrientation === HomeTouchEnum.right,
                                    })}
                                    src="/assets/light.png"
                                />
                            )}
                    </div>
                </div>
            </div>
            {screen === Screen.Clicker && (
                <div className="px-4 w-full flex pb-7 flex-col gap-2 z-[60] items-center">
                    {progressBar()}
                </div>
            )}
            {isLoadingFarm && (
                <div className="absolute z-[85] top-0 h-full pointer-events-none w-full flex justify-center items-center bg-black/50">
                    <Loader />
                </div>
            )}
        </div>
    );
});
