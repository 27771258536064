import * as THREE from 'three';
import { AudioLoader, Color, ShaderMaterial, TOUCH, Vector2, Vector3 } from 'three';
import { BASE_SUBTRACT, EggPhysics, RADIUS } from './EggPhysics';
import { EggModel, EggState } from './EggModel';
import GUI, { Controller } from 'lil-gui';
import { clamp, getServerTime, lerp, randomRange } from '../../../helpers/utils';
import { Config, Store } from '../../../helpers/store';
import { FarmRewardCalculateService } from '../../../helpers/farm-reward-calculate.service';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { globals } from '../../../helpers/globals';
import { qualityDetector } from '../../../helpers/qualityDetector';
import { isPerfStats } from '../../../helpers/config';
import EventEmitter, { EVENTS } from '../../../helpers/eventemitter';
import eruda from 'eruda';
import { CoroutineManager } from '../../../helpers/coroutineManager';
import { differenceInMilliseconds } from 'date-fns';
import { MarchingCubes } from './modules/MarchingCubes';
import { FarmSessionInfoDto, UserInfoDto } from '../../../api/tor-api';

const MAX_SPEED = 1;

const MAX_BALL_COUNT = 8;
// const MIN_BALL_COUNT = 1;
export const TARGET_BULB_COUNT = process.env.REACT_APP_TARGET_BULB_COUNT
    ? parseInt(process.env.REACT_APP_TARGET_BULB_COUNT)
    : 100;
const MAX_WAVE_COUNT = 0;
const ALLOW_MISSCLICKS = true;

export enum AudioKind {
    TAP,
    TAP_FARMING,
    CLAIM,
    START_FARMING,
    MUSIC,
}

const eggModel: EggModel = {
    ballCount: MAX_BALL_COUNT,
    mutagen: 0,
    sessionProgress: null,
    angle: 0,
    dragPoint: null,
    draggedBall: null,
    bulbOrigin: new Array<Vector3>(),
    bulbTouchTime: new Array<number>(),
    temperature: 0,
    claimTime: -10000,
    bulbElapsedTime: -10000,
    ballRadiusScale: 1,
    bulbCount: 0,
    eggPhysics: null,
    statsAdded: false,
    disappearProgress: 0,
    stateCopy: EggState.NONE,
    pushNeeded: false,
    pushOrigin: new Vector3(),
};

export class EggView {
    private root: HTMLDivElement;

    private renderer!: THREE.WebGLRenderer;
    private scene!: THREE.Scene;
    private camera!: THREE.PerspectiveCamera;

    private swipeControls: OrbitControls | null = null;
    private requestId: number;
    private marchingCubes: MarchingCubes | null = null;
    gui: GUI | null = null;
    guiControllers: Controller[] = [];
    folders: GUI[] = [];
    farmRewardCalculateService = new FarmRewardCalculateService();

    private material!: ShaderMaterial;
    private activeRotation = false;
    private store: Store | null = new Store();
    private startClickPos: Vector2 | null = null;
    private showDebugPlanes = false;
    marchingCubesResolution: number = 50;
    config!: Config;
    user: UserInfoDto | null = null;
    sounds: Record<number, THREE.PositionalAudio> = {};
    state: EggState = EggState.NONE;
    tapCount: number = 0;

    constructor(root: HTMLDivElement) {
        this.root = root;

        if (!eggModel.eggPhysics) {
            eggModel.eggPhysics = new EggPhysics(eggModel);
        }
        this.initRenderer();
        this.initScene();
        this.initCamera();
        this.initSwipeControls();
        this.initInput();
        if (isPerfStats()) {
            this.addStats();
        }
        this.requestId = requestAnimationFrame(this.render);

        EventEmitter.on(EVENTS.DOWN, this.onMouseDown, this);
        EventEmitter.on(EVENTS.MOVE, this.onMouseMove, this);
        EventEmitter.on(EVENTS.UP, this.onMouseUp, this);

        this.loadAudio('/music/farming.mp3', AudioKind.START_FARMING);
        this.loadAudio('/music/tap.mp3', AudioKind.TAP);
        this.loadAudio('/music/tap_farming.mp3', AudioKind.TAP_FARMING);
        this.loadAudio('/music/claim.mp3', AudioKind.CLAIM);
        this.loadAudio('/music/background.mp3', AudioKind.MUSIC);
    }

    private loadAudio(url: string, audiokind: AudioKind) {
        new AudioLoader().load(url, (buffer: AudioBuffer) => {
            let positionalAudio = new THREE.PositionalAudio(globals.listener);
            positionalAudio.setBuffer(buffer);
            this.sounds[audiokind] = positionalAudio;
        });
    }

    public createGUI() {
        if (!this.gui || this.gui.folders.length !== 0) {
            return;
        }
        this.gui.show(); // show

        {
            const folder = this.gui.addFolder('eggModel');
            this.folders.push(folder);
            folder.add(eggModel, 'ballCount');
            folder.add(eggModel, 'mutagen').decimals(2);
        }
        // {
        //     const folder = this.gui.addFolder('setting');
        //     this.folders.push(folder);
        //     folder.add(qualityDetector, 'quality').decimals(2);
        // }
    }

    private initRenderer = (className?: string) => {
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            logarithmicDepthBuffer: true,
            alpha: false,
        });
        this.renderer.outputColorSpace = THREE.SRGBColorSpace;
        this.renderer.setClearColor(0x000000, 0);
        this.renderer.autoClear = false;

        if (className) this.renderer.domElement.classList.add(className);
        this.root.appendChild(this.renderer.domElement);
    };

    vertexShader = `
    varying vec3 vNormal;
    varying vec4 vPosition;
    varying vec4 vOPosition;
    varying vec3 vU;
    uniform float waveScale;
    uniform float time;
    // uniform float bulbTime[${MAX_WAVE_COUNT}];
    // uniform vec3 origin[${MAX_WAVE_COUNT}];
    uniform vec3 bulbCount;

    const float speed = 10.0;

    void main() {
        // float distortion = 0.0005 * waveScale; // wave height
        // float scale = 30.0 * waveScale;
        // float scaleBigWave = 10.0 * waveScale;

        // vOPosition = modelViewMatrix * vec4( position, 1.0 );
        vU = normalize( vec3( modelViewMatrix * vec4( position, 1.0 ) ) );

        //float displacement = 0.0;
        // for (int i = 0; i < ${MAX_WAVE_COUNT}; i++) {
        //     float bTime = bulbTime[i];
        //     vec3 orig = origin[i];
        //     float distance = length(orig - position.xyz);
        //     float bigWave = cos(max(-3.14/2.0, min(3.14/2.0, distance * scaleBigWave - bTime * speed + 3.14/2.0)));
        //     float b = (sin(distance * scale - bTime*speed) + 1.0);
        //     displacement += b * bigWave * distortion;
        // }

        vec3 newPosition = position/* + normal * displacement*/;

        vOPosition = modelViewMatrix * vec4( newPosition, 1.0 );
        gl_Position = projectionMatrix * vOPosition;

        vPosition = vec4( position, 1.0 );
        vNormal = normalMatrix * normal;
    }`;

    fragmentShader = `

    uniform sampler2D textureMap;
    uniform vec3 color;
    uniform vec3 color2;
    uniform float outerColorSize;
    uniform float texScale;

    varying vec3 vNormal;
    varying vec4 vPosition;
    varying vec4 vOPosition;
    varying vec3 vU;

    void main() {

        vec3 finalNormal = vNormal;

        vec3 r = reflect( normalize( vU ), normalize( finalNormal ) );
        float m = 2.0 * sqrt( r.x * r.x + r.y * r.y + ( r.z + 1.0 ) * ( r.z + 1.0 ) );
        vec2 calculatedNormal = vec2( r.x / m + 0.5,  r.y / m + 0.5 );

        float rim = (1.0 - outerColorSize * 0.5) * max( 0., abs( dot( normalize( vNormal ), normalize( -vOPosition.xyz ) ) ) );
        vec3 c1 = rim * color2;
        vec3 c2 = color * ( 1. - rim );
//        vec3 base = vec3(max(c1.r, c2.r), max(c1.g, c2.g), max(c1.b, c2.b)) * texture2D( textureMap, calculatedNormal ).rgb;
        vec3 base = c1 + c2; //  * texture2D( textureMap, calculatedNormal ).rgb;

        gl_FragColor = vec4( base.rgb, 1. );
    }`;

    loadTexture(url: string) {
        var loader = new THREE.ImageLoader();
        loader.crossOrigin = 'anonymous';

        const texture = new THREE.Texture(undefined);

        const image = loader.load(
            url,
            function () {
                texture.needsUpdate = true;
            },
            (p) => {},
            (e) => {
                console.error(e);
            },
        );

        texture.image = image;

        return texture;
    }

    private initScene = () => {
        this.scene = new THREE.Scene();

        this.scene.background = new Color('#000000');

        // const LIGHT_COLOR = new Color('#FFFFFF');
        // const ambientLight = new THREE.AmbientLight(LIGHT_COLOR, 0.5);
        // this.scene.add(ambientLight);

        // const directionalLight = new THREE.DirectionalLight(LIGHT_COLOR, 1);
        // directionalLight.position.set(7, 7, 7);
        // directionalLight.target.position.set(0, 0, 0);
        // this.scene.add(directionalLight);
        // this.scene.add(directionalLight.target);
        //

        this.createMarchingCubes(this.marchingCubesResolution);

        // {
        //
        //     const geometry = new THREE.CylinderGeometry(1, 1, 0.25, 20);
        //     const mesh = new THREE.Mesh(geometry, material);
        //     mesh.scale.set(0.3, 0.3, 0.3);
        //     this.scene.add(mesh);
        // }

        // new GLTFLoader().load('/textures/background.glb', (obj) => {
        //     this.scene.add(obj.scene);
        //
        //     let rotation = new Quaternion().setFromEuler(new Euler(Math.PI/2, 0, 0));
        //     obj.scene.rotation.setFromQuaternion(rotation);
        //     obj.scene.position.set(0.5, -1, 0);
        //     obj.scene.scale.set(0.3, 0.3, 0.3);
        // });
    };

    private createMarchingCubesMaterial() {
        this.material = new THREE.ShaderMaterial({
            uniforms: {
                textureMap: { value: null },
                texScale: { value: 5 },
                color: { value: new Color() },
                color2: { value: new Color() },
                resolution: { value: new THREE.Vector2(0, 0) },
                time: { value: 0 },
                waveScale: { value: 1.0 },
                outerColorSize: { value: 0 },
                bulbTime: { value: [] },
                origin: { value: [] },
            },
            vertexShader: this.vertexShader,
            fragmentShader: this.fragmentShader,
            // depthTest: true,
            // depthWrite: true,
            // depthFunc: LessDepth,
            side: THREE.DoubleSide,
            // wireframe: true,
        });
        for (let i = 0; i < MAX_WAVE_COUNT; i++) {
            this.material.uniforms.bulbTime.value.push(-100000);
            this.material.uniforms.origin.value.push(new Vector3());
        }

        this.material.uniforms.texScale.value = 5;
        this.material.uniforms.textureMap.value = this.loadTexture('/textures/ball_1k_bw.png');
        this.material.uniforms.textureMap.value.wrapS = THREE.ClampToEdgeWrapping;
        this.material.uniforms.textureMap.value.wrapT = THREE.ClampToEdgeWrapping;
    }

    private createMarchingCubes(resolution: number) {
        this.marchingCubesResolution = resolution;
        console.log('marchingCubes resolution', this.marchingCubesResolution);
        if (this.marchingCubes === null) {
            this.createMarchingCubesMaterial();

            this.marchingCubes = new MarchingCubes(
                this.marchingCubesResolution,
                this.material,
                true,
                false,
                50000,
            );
            // this.marchingCubes.scale.set(1.5, 1.5, 1.5);
            this.scene.add(this.marchingCubes);
        } else {
            this.marchingCubes.init(this.marchingCubesResolution);
        }
    }

    private checkModel() {
        //let weekCount = (this.user?.totalClaimedSessions ?? 0) / 3 / 7;
        let progression = 0;
        switch (this.state) {
            case EggState.GROWTH:
                progression = this.tapCount / TARGET_BULB_COUNT;
                break;
            case EggState.HATCHING:
                const session = this.user?.farmSessionInfo;
                if (session) {
                    let startTime = new Date(session.lastInteractionTime);
                    let currentTime = getServerTime(this.config?.serverTimeDiffMs);
                    let finishTime = new Date(session.endTime);
                    let time1 = differenceInMilliseconds(currentTime, startTime);
                    let time2 = differenceInMilliseconds(finishTime, startTime);
                    progression = clamp(1 - time1 / time2, 0, 1);
                } else {
                    progression = 1;
                }
                break;
        }

        let premium = false; // this.user?.isPremium;
        let color0 = premium ? new Color(0x48ff00) : new Color(0x48bb00); // inner // 0x009157
        let color1 = premium ? new Color(0xf47fff) : new Color(0xd4ff00); // 0xf6ff00
        // if (progression > 1) {
        //     // color1 = color1.multiplyScalar(10);
        //     // color0 = color0.multiplyScalar(100);
        // }
        this.material.uniforms.color.value.set(color1);
        this.material.uniforms.color2.value.set(color0);

        this.material.uniforms.time.value = globals.clock.elapsedTime;
        let outerColorSize = 0;
        switch (this.store?.state) {
            case EggState.HATCHING:
                outerColorSize = eggModel.sessionProgress ?? 0;
                break;
            case EggState.DISAPPEAR:
                outerColorSize = 1 - eggModel.disappearProgress;
                break;
        }
        //        console.log('state', this.store?.state, sessionCount, lerpFactor, outerColorSize);
        this.material.uniforms.outerColorSize.value = outerColorSize;

        // this.material.uniforms.vEye.value.set(this.camera.position);
        let bulbP = eggModel.bulbCount / TARGET_BULB_COUNT;
        eggModel.temperature = 0.1 + bulbP;
        for (let i = 0; i < eggModel.bulbOrigin.length; i++) {
            this.material.uniforms.origin.value[i].copy(eggModel.bulbOrigin[i]);

            eggModel.bulbElapsedTime = globals.clock.elapsedTime - eggModel.bulbTouchTime[i];

            this.material.uniforms.bulbTime.value[i] = eggModel.bulbElapsedTime;
            // if (canStartFarmingSession(this.user)) {
            //     eggModel.temperature += Math.max(0, 1 - eggModel.bulbElapsedTime);
            // }
        }

        for (let i = eggModel.bulbOrigin.length; i < MAX_WAVE_COUNT; i++) {
            this.material.uniforms.bulbTime.value[i] = -1000000;
        }
        this.material.uniforms.waveScale.value = lerp(0.6, 2, bulbP);

        const oldBallCount = eggModel.eggPhysics!.data.balls.length;

        // if (this.store?.state !== EggState.HATCHING) {
        //     eggModel.ballCount = Math.floor(
        //         MIN_BALL_COUNT + (MAX_BALL_COUNT - MIN_BALL_COUNT) * Math.pow(bulbP, 0.5),
        //     );
        //     // console.log('eggModel.ballCount!!!', eggModel.ballCount, this.getState());
        // }

        for (let i = oldBallCount; i < eggModel.ballCount; i++) {
            let maxY = Number.NEGATIVE_INFINITY;
            let bestBallIndex = -1;
            for (let j = 0; j < eggModel.eggPhysics!.data.balls.length; j++) {
                let ball1 = eggModel.eggPhysics!.data.balls[j];
                if (ball1.pos.y > maxY) {
                    maxY = ball1.pos.y;
                    bestBallIndex = j;
                }
            }
            let data;
            if (bestBallIndex >= 0) {
                let bestBall = eggModel.eggPhysics!.data.balls[bestBallIndex];
                let pos = bestBall.pos.clone();
                let kindex = i - oldBallCount;
                if (kindex === 0) {
                    pos.add(new Vector3(0, bestBall.r * 2, 0));
                } else {
                    let f = randomRange(0, 5);
                    if (f < 1) {
                        pos.add(new Vector3(bestBall.r * 2, bestBall.r * 0.01, 0));
                    } else if (f < 2) {
                        pos.add(new Vector3(-bestBall.r * 2, bestBall.r * 0.01, 0));
                    } else if (f < 3) {
                        pos.add(new Vector3(0, bestBall.r * 0.01, bestBall.r * 2));
                    } else {
                        pos.add(new Vector3(0, bestBall.r * 0.01, -bestBall.r * 2));
                    }
                }
                data = eggModel.eggPhysics!.createBallDataWithPos(pos);
            } else {
                data = eggModel.eggPhysics!.createBallData(i);
            }
            eggModel.eggPhysics!.data.balls.push(data);
        }
        //        console.log('progression', progression);
        for (let i = 0; i < eggModel.eggPhysics!.data.balls.length; i++) {
            const ball = eggModel.eggPhysics!.data.balls[i];
            ball.r = lerp(RADIUS * 0.5, RADIUS * 2.5, progression);
            ball.subtract = lerp(BASE_SUBTRACT, BASE_SUBTRACT * 0.2, progression);
        }
        // this.material.uniforms.normalScale.value = Math.sin(eggModel.mutagen * 0.001);
        // this.material.uniforms.texScale.value = Math.cos(eggModel.mutagen * 0.0001 * 5);
    }

    private checkQuality() {
        // const needResolution = Math.round(lerp(85, 50, ((eggModel.eggPhysics?.data.balls.length ?? 1) - MIN_BALL_COUNT) / MAX_BALL_COUNT));
        // if (this.marchingCubesResolution !== needResolution) {
        //     this.createMarchingCubes(needResolution);
        // }
    }

    private initCamera() {
        const box = this.root.getBoundingClientRect();
        let aspect = box.width / box.height;
        const fov = 1;
        this.camera = new THREE.PerspectiveCamera(fov, aspect, 0.01, 200);
        this.camera.position.set(0, 100, 0);
        this.camera.lookAt(0, 0, 0);
        this.resizeRendererToDisplaySize(true);

        // this.controls = new OrbitControls(this.camera, canvas);
        // this.controls.target.set(0, 0, 0);
        // this.controls.autoRotate = false;
        // this.controls.enableZoom = false;
        // // this.controls.enableDamping = true;
        // // this.controls.dampingFactor = 0.5;
        // this.controls.mouseButtons = {
        //     LEFT: null,
        //     MIDDLE: null,
        //     RIGHT: THREE.MOUSE.ROTATE,
        // };
    }

    onMouseDown(e: any) {
        if (e.buttons === 1) {
            this.startTouchEvent(e.pageX, e.pageY, ALLOW_MISSCLICKS);
        }
    }

    onMouseMove(e: any) {
        this.moveTouchEvent(e.pageX, e.pageY);
    }

    onMouseUp(e: any) {
        this.finishTouchEvent();
    }

    private initSwipeControls() {
        const canvas = this.renderer.domElement;

        this.swipeControls = new OrbitControls(this.camera, canvas); // fake
        this.swipeControls.target.set(0, 0, 0);
        this.swipeControls.enableDamping = false;
        //this.swipeControls.minPolarAngle = Math.PI / 2;
        //this.swipeControls.maxPolarAngle = Math.PI / 2;
        //this.swipeControls.maxPolarAngle = Math.PI / 2;
        this.swipeControls.enableZoom = false;

        this.swipeControls.mouseButtons = {
            LEFT: null,
            MIDDLE: null,
            RIGHT: THREE.MOUSE.ROTATE,
        };
        this.swipeControls.touches = {
            ONE: null,
            TWO: TOUCH.ROTATE,
        };

        // this.swipeControls.addEventListener('start', () => {
        //     console.log('swipeControls start');
        //     eggModel.rotationSpeed = 0;
        //     this.activeRotation = true;
        // });
        // this.swipeControls.addEventListener('end', () => {
        //     console.log('swipeControls finish', eggModel.rawRotationSpeed);
        //     if (eggModel.rawRotationSpeed !== null) {
        //         eggModel.rotationSpeed = eggModel.rawRotationSpeed * 0.3;
        //     }
        //     this.lastAzimuthalAngle = null;
        //     this.activeRotation = false;
        //     this.onTap();
        // });

        // this.root.addEventListener('mouseup', (e) => , false);
        // this.root.addEventListener('mouseleave', (e) => this.finishTouchEvent(), false);

        // this.root.addEventListener(
        //     'touchstart',
        //     (e) => {
        //         this.startTouchEvent(e.touches[0].clientX, e.touches[0].clientY, ALLOW_MISSCLICKS);
        //     },
        //     false,
        // );
        // this.root.addEventListener(
        //     'touchmove',
        //     (e) => {
        //         this.moveTouchEvent(e.touches[0].clientX, e.touches[0].clientY);
        //         e.preventDefault();
        //     },
        //     false,
        // );
        // this.root.addEventListener('touchend', (e) => this.finishTouchEvent(), false);
        // this.root.addEventListener('touchcancel', (e) => this.finishTouchEvent(), false);
    }

    private startTouchEvent(mouseX: number, mouseY: number, forced: boolean) {
        //if (!this.calcBulbOrigin(mouseX, mouseY, forced)) {
        this.activeRotation = true;
        this.startClickPos = new Vector2(mouseX, mouseY);
        this.calcDragPoint(mouseX, mouseY);
        //}

        // const geometry = new THREE.SphereGeometry(0.1, 5, 5, 20);
        // const material = new THREE.MeshPhongMaterial({ color: '#45FF00' });
        // const mesh = new THREE.Mesh(geometry, material);
        // mesh.position.copy(point0);
        // this.scene.add(mesh);
    }

    private calcDragPoint(mouseX: number, mouseY: number) {
        const point0 = this.getDragPoint(mouseX, mouseY);

        eggModel.dragPoint = point0;
    }

    private getDragPoint(mouseX: number, mouseY: number) {
        const screenPos = this.getScreenPos(mouseX, mouseY);
        const s = this.camera.getWorldPosition(new Vector3());
        const v = new Vector3(screenPos.x, screenPos.y, 0).unproject(this.camera).sub(s);

        const t0 = -s.y / v.y;
        const point0 = new Vector3(s.x + v.x * t0, s.y + v.y * t0, s.z + v.z * t0);
        if (this.marchingCubes) {
            point0.x /= this.marchingCubes.scale.x / 0.5;
            point0.y /= this.marchingCubes.scale.y / 0.5;
            point0.z /= this.marchingCubes.scale.z / 0.5;
        }
        return point0;
    }

    private getScreenPos(mouseX: number, mouseY: number) {
        const box = this.root.getBoundingClientRect();
        const screenPos = new Vector2(
            ((mouseX - box.left) / this.root.clientWidth) * 2 - 1,
            1 - ((mouseY - box.top) / this.root.clientHeight) * 2,
        );
        return screenPos;
    }

    private moveTouchEvent(mouseX: number, mouseY: number) {
        if (this.activeRotation) {
            this.calcDragPoint(mouseX, mouseY);
        }
    }

    private finishTouchEvent() {
        // console.warn('event up', eggModel.rotationSpeed);
        if (this.startClickPos !== null) {
            // const distance = this.startClickPos.distanceTo(new Vector2(mouseX, mouseY));
            // if (distance < 5) {
            //     // just click
            //     eggModel.ballCount++;
            // }
            this.startClickPos = null;
        }
        eggModel.dragPoint = null;
        this.activeRotation = false;
        // this.onTap();
    }

    private render = () => {
        globals.deltaTime = globals.clock.getDelta();
        // if (this.user) {
        //     this.updateGui();
        // }
        CoroutineManager.Next();
        globals.stats.update();
        qualityDetector.update();
        this.checkRotationSpeed();

        this.checkModel();
        this.checkQuality();

        this.resizeRendererToDisplaySize(false);

        eggModel.stateCopy = this.store?.state ?? EggState.NONE;
        eggModel.eggPhysics!.animate(globals.deltaTime);

        if (this.marchingCubes) {
            this.drawBalls(this.marchingCubes);
        }

        this.renderer.render(this.scene, this.camera);

        this.requestId = requestAnimationFrame(this.render);
    };

    private updateGui() {
        this.guiControllers.forEach((x) => x.updateDisplay());
        this.folders.forEach((x) => x.controllers.forEach((y) => y.updateDisplay()));
    }

    drawBalls(object: MarchingCubes) {
        object.reset();

        let data = eggModel.eggPhysics!.data;
        eggModel.ballRadiusScale = 3 + Math.pow(data.balls.length, 0.5);

        for (let i = 0; i < data.balls.length; i++) {
            const b = data.balls[i];
            object.addBall(
                b.visualPos.x + 0.5,
                b.visualPos.y + 0.5,
                b.visualPos.z + 0.5,
                b.r * eggModel.ballRadiusScale,
                b.subtract,
            );
            // data.balls.forEach((a) => {
            //     const steps = Math.max(3, 12 - data.balls.length);
            //
            //     for (let i = 1; i < steps; i++) {
            //         let x = lerp(a.visualPos.x, b.visualPos.x, i / steps);
            //         let y = lerp(a.visualPos.y, b.visualPos.y, i / steps);
            //         let z = lerp(a.visualPos.z, b.visualPos.z, i / steps);
            //         let r = Math.max(
            //             lerp(a.r, b.r * 0.3, i / steps),
            //             lerp(a.r * 0.3, b.r, i / steps),
            //         );
            //         object.addBall(x + 0.5, y + 0.5, z + 0.5, r, subtract);
            //     }
            // });
        }

        if (this.showDebugPlanes) {
            object.addPlaneY(0.5, 1);
            object.addPlaneX(0.5, 1);
            object.addPlaneZ(0.5, 1);
        }

        object.update();
    }

    private resizeRendererToDisplaySize = (forced: boolean) => {
        // const pixelRatio = window.devicePixelRatio;
        const canvas = this.renderer.domElement;

        const box = this.root.getBoundingClientRect();
        if (!box) {
            return false;
        }

        const width = box.width;
        const height = box.height;

        const needResize = canvas.width !== width || canvas.height !== height;

        if (needResize || forced) {
            let aspect = box.width / box.height;
            this.renderer.setSize(width, height, false);
            this.camera.fov = 1.05 / aspect;

            this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
            this.camera.updateProjectionMatrix();
        }

        return needResize;
    };

    public destroy = () => {
        cancelAnimationFrame(this.requestId);
        this.renderer.dispose();
        this.renderer.domElement.remove();
        EventEmitter.off(EVENTS.DOWN, this.onMouseDown, this);
        EventEmitter.off(EVENTS.MOVE, this.onMouseMove, this);
        EventEmitter.off(EVENTS.UP, this.onMouseUp, this);
        console.log('EggView:destroy');
    };

    private initInput() {
        window.addEventListener(
            'keydown',
            (event) => {
                switch (event.key) {
                    case '`':
                        if (this.gui) {
                            this.gui.destroy();
                            this.gui = null;
                        } else {
                            this.gui = new GUI();
                            this.createGUI();
                        }
                        this.addStats();
                        eruda.init();
                        break;
                    case 'p':
                        this.showDebugPlanes = !this.showDebugPlanes;
                        break;
                }
            },
            false,
        );
    }

    private checkRotationSpeed() {
        // eggModel.bulbCount = 0;
    }

    public calcSessionMetrics(session: FarmSessionInfoDto) {
        if (!this.config?.config) {
            throw new Error('no config');
        }
        let startTime = new Date(session.lastInteractionTime);
        let currentTime = getServerTime(this.config?.serverTimeDiffMs);
        let finishTime =
            new Date(session.endTime) <= currentTime ? new Date(session.endTime) : currentTime;
        let metrics = this.farmRewardCalculateService.linearCalculateReward(
            startTime,
            finishTime,
            session.lastSpeed,
            this.config.config.FIXED_INTERVAL_H,
            this.config.config.DECAY_INTERVAL_H,
            this.config.config.DECAY_RATE,
            this.config.config.MIN_SERVER_SPEED,
            this.config.config.REWARD_PER_HOUR,
        );

        const rotationSpeed = this.convertServerSpeedToRotation(metrics.currentSpeed.toNumber());
        const currentFarmedCoins = session.lastFarmedCoins + metrics.reward.toNumber(); // use speed
        return { rotationSpeed, currentFarmedCoins };
    }

    convertServerSpeedToRotation(speed: number) {
        return speed * MAX_SPEED;
    }

    convertRotationToServerSpeed(speed: number) {
        if (!this.config?.config) {
            throw new Error('no config');
        }
        return clamp(
            speed / MAX_SPEED,
            this.config.config.MIN_SERVER_SPEED,
            this.config.config.MAX_SERVER_SPEED,
        );
    }

    private addStats() {
        if (!eggModel.statsAdded) {
            eggModel.statsAdded = true;
            document.body.appendChild(globals.stats.dom);
            globals.stats.showPanel(1);
        }
    }

    setConfig(config: Config) {
        this.config = config;
    }

    setUser(user: UserInfoDto) {
        this.user = user;
    }

    playSound(audio: AudioKind) {
        const sound = this.sounds[audio];
        if (sound) {
            if (sound.isPlaying) {
                sound.stop();
            }
            console.log('Play sound', audio);
            sound.play();
        }
    }

    playMusic() {
        const sound = this.sounds[AudioKind.MUSIC];
        if (sound) {
            if (sound.isPlaying) {
                return;
            }
            console.log('Start music');
            sound.setLoop(true);
            sound.setVolume(0);
            sound.play();
            CoroutineManager.StartIterate(
                5000,
                (t) => {
                    sound.setVolume(t);
                },
                () => {
                    sound.setVolume(1);
                },
            );
        }
    }

    public setState(newState: EggState) {
        this.state = newState;
    }

    public setTapCount(newTapCount: number) {
        this.tapCount = newTapCount;
    }
}
