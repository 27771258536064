import { FC } from 'react';
import { DailyRewardItemDto } from '../../../../api/tor-api';
import Reward from '../Reward/Reward';
import styles from './RewardsList.module.scss';

interface RewardsListProps {
    rewards: DailyRewardItemDto[] | undefined;
    isLoading?: boolean;
}
const RewardsList: FC<RewardsListProps> = ({ rewards, isLoading }) => {
    if (isLoading) {
        const skeletonsArray = [1, 2, 3, 4, 5, 6, 7];
        return (
            <div className={styles.wrapper}>
                {skeletonsArray.map((element) => (
                    <div className={styles.skeleton} key={element}></div>
                ))}
            </div>
        );
    }
    return (
        <div className={styles.wrapper}>
            {rewards !== undefined &&
                rewards.map((reward) => <Reward key={reward.id} reward={reward} />)}
        </div>
    );
};

export default RewardsList;
