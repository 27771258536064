import { FC } from 'react';
import styles from './NotificationBadge.module.scss';

interface NotificationBadgeProps {
    count: number | undefined;
}

const NotificationBadge: FC<NotificationBadgeProps> = ({ count }) => {
    if (count) {
        const displayCount = count > 99 ? '99+' : count;
        return <span className={styles.badge}>{displayCount}</span>;
    } else return null;
};

export { NotificationBadge };
