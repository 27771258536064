import { useMutation, useQuery } from '@tanstack/react-query';
import { torApi } from '../../../api/tor-api';
import { useStore } from '../../../store/globalStore';
import { useTasksStore } from '../../../store/tasksSlice';

export const useGetTasks = () => {
    return useQuery({
        queryKey: ['tasks'],
        queryFn: torApi.api.getApiV1Tasks,
    });
};

export const useClaimTask = () => {
    const mergeUser = useStore((state) => state.mergeUser);
    const setDailyRewards = useTasksStore((state) => state.setDailyRewards);

    return useMutation({
        mutationFn: torApi.api.postApiV1TasksClaim,
        onSuccess: (data) => {
            const updatedUser = data.data.user;
            const updatedDailyReward = data.data.dailyReward;

            mergeUser(updatedUser);
            setDailyRewards(updatedDailyReward);
        },
    });
};
