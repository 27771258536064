import { Outlet } from 'react-router-dom';
import { Navigation } from '../../modules/Navigation';

const UserRoot = () => {
    return (
        <>
            <div className="h-full">
                <Outlet />
            </div>
            <Navigation />
        </>
    );
};

export { UserRoot };
