import React from 'react';
import styles from './index.module.scss';
import { ReactComponent as Phone } from '../../../../assets/phone.svg';

const RotationPlug = () => {
    return (
        <div className={styles.wrapper}>
            <Phone className={styles.phone} />
            <span className={styles.text}>Rotate your device</span>
        </div>
    );
};

export { RotationPlug };
