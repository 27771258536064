const prefixes = ["Tara", "Ska", "Ju", "Beet", "Creep", "Scur", "Scut", "Rusti", "Skat", "Zing", "Bug", "Shrik"]
const midfixes = ["spin", "swift", "crush", "buzz", "speed", "jump", "dart", "dash", "snap", "flit", "snap", "run"]
const suffixes = ["ter", "us", "ix", "or", "ox", "zy", "ard", "ick", "ster", "ion", "ex", "oid"]

function randomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function roachName() {
  const prefix = prefixes[randomInt(0, prefixes.length - 1)]
  const midfix = midfixes[randomInt(0, prefixes.length - 1)]
  const suffix = suffixes[randomInt(0, prefixes.length - 1)]
  const post = randomInt(0, 10000)

  return prefix + midfix + suffix + post
}