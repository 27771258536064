import React from 'react';
import { RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactGA from 'react-ga4';
import { ConfigProvider } from 'antd';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ErrorContextProvider } from './store/errorContext';
import { SDKWrapper } from './helpers/sdkWrapper';
import { isErudaConsole } from './helpers/config';
import eruda from 'eruda';
import { getRawInitData } from './helpers/getInitData';
import { router } from './app/routes/Routes';
import { mainTheme } from './helpers/colors';
import { Toaster } from 'sonner';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <ErrorContextProvider>
            <QueryClientProvider client={queryClient}>
                <SDKWrapper>
                    <ConfigProvider theme={mainTheme}>
                        <RouterProvider router={router} />
                        <Toaster position="top-center" theme="dark" richColors />
                    </ConfigProvider>
                </SDKWrapper>
            </QueryClientProvider>
        </ErrorContextProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.addEventListener('keydown', (event) => {
    switch (event.key) {
        case 'i':
            const { initDataRaw } = getRawInitData();

            prompt('init data', initDataRaw ?? 'undefined');
            break;
    }
});

if (isErudaConsole()) {
    eruda.init();
}

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}
