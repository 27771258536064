import React, { FC, ReactNode } from 'react';
import styles from './Modal.module.scss';
import Portal from '../Portal/Portal';

interface ModalProps {
    children: ReactNode;
    onClose(): void;
}

const Modal: FC<ModalProps> = ({ children, onClose }) => {
    return (
        <Portal>
            <div className={styles.wrapper}>
                <button className={styles.closeBtn} onClick={onClose}>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g id="close_24px">
                            <path
                                id="icon/navigation/close_24px"
                                d="M12.2 3.80664C12.0754 3.6818 11.9063 3.61165 11.73 3.61165C11.5536 3.61165 11.3845 3.6818 11.26 3.80664L7.99998 7.05997L4.73998 3.79997C4.61543 3.67514 4.44633 3.60498 4.26998 3.60498C4.09364 3.60498 3.92453 3.67514 3.79998 3.79997C3.53998 4.05997 3.53998 4.47997 3.79998 4.73997L7.05998 7.99997L3.79998 11.26C3.53998 11.52 3.53998 11.94 3.79998 12.2C4.05998 12.46 4.47998 12.46 4.73998 12.2L7.99998 8.93997L11.26 12.2C11.52 12.46 11.94 12.46 12.2 12.2C12.46 11.94 12.46 11.52 12.2 11.26L8.93998 7.99997L12.2 4.73997C12.4533 4.48664 12.4533 4.05997 12.2 3.80664Z"
                                fill="white"
                                fill-opacity="0.8"
                            />
                        </g>
                    </svg>
                </button>

                {children}
            </div>
        </Portal>
    );
};

export default Modal;
