import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface TapCountState {
    tapCount: number;

    increaseTapCount(): void;
    resetTapCount(): void;
}

export const useTapCount = create<TapCountState>()(
    immer((set) => ({
        tapCount: 0,

        increaseTapCount: () =>
            set((state) => {
                state.tapCount += 1;
            }),
        resetTapCount: () =>
            set((state) => {
                state.tapCount = 0;
            }),
    })),
);
