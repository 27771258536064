import { FC } from 'react';
import clsx from 'clsx';
import styles from './Upgrade.module.scss';
import Button from '../Button/Button';
import { useRoachStore } from '../../store/roachSlice';
import { useUpgrade } from '../../modules/Upgrade/api/upgradeQuery';
import { RoachEnergyUpgradeInfoDto } from '../../api/tor-api';
import { ReactComponent as Coin } from '../../assets/mtgn.svg';

interface Props {
    info: RoachEnergyUpgradeInfoDto;
}

const EnergyLimit: FC<Props> = ({ info }) => {
    const currentRoach = useRoachStore((state) => state.currentRoach);
    const roachId = currentRoach?.id || 0;
    const isCost = info.nextUpgradeCost !== undefined;
    const cost = info.nextUpgradeCost;

    const { mutate, data, isPending } = useUpgrade();

    const handleClick = () => {
        mutate({
            roachId,
            type: 'tank_size',
        });
    };

    console.log('upgData', data);
    return (
        <div className={clsx(styles.wrapper, styles.secondLayer)}>
            <div className={styles.header}>
                <span className={styles.icon}>🔋</span>
                <span className={styles.lvl}>lvl {info.upgradesCount + 1}</span>
                <h3 className={styles.title}>Energy Limit</h3>
                <span className={styles.amount}>
                    {currentRoach?.maxEnergy}
                    <span className={styles.additional}>{`+${info.upgradeSize}`}</span>
                </span>
            </div>

            <p className={styles.description}>
                This roach will run more energetically — it can run for the <b>same time</b>, but
                will get <b>more rewards</b> for races
            </p>
            {isCost && (
                <Button onClick={handleClick} className={styles.purchase} disabled={isPending}>
                    Upgrade
                    <Coin />
                    <span>{cost}</span>
                </Button>
            )}
        </div>
    );
};

export default EnergyLimit;
