import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { torApi } from '../../../api/tor-api';
import { useStore } from '../../../store/globalStore';

export const useGetUpgrades = (roachId: number) => {
    return useQuery({
        queryKey: ['upgrade', roachId],
        queryFn: () => torApi.api.getApiV1RoachUpgradeRoachId(roachId),
    });
};

export const useUpgrade = () => {
    const queryClient = useQueryClient();
    const mergeUser = useStore((state) => state.mergeUser);
    return useMutation({
        mutationFn: torApi.api.postApiV1RoachUpgrade,
        onSuccess: (data) => {
            const updatedUser = data.data.user;

            mergeUser(updatedUser);
            queryClient.invalidateQueries({ queryKey: ['roaches'] });
            queryClient.invalidateQueries({ queryKey: ['upgrade'] });
        },
    });
};
