import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';
import clsx from 'clsx';
import styles from './Button.module.scss';

interface ButtonProps
    extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    children: ReactNode;
}

const Button: FC<ButtonProps> = ({ children, className, ...props }) => {
    return (
        <button className={clsx(styles.button, className)} {...props}>
            {children}
        </button>
    );
};

export default Button;
