import { Vector3 } from 'three';
import { EggPhysics } from './EggPhysics';

export enum EggState {
    NONE,
    GROWTH,
    HATCHING,
    DISAPPEAR,
}

export interface EggModel {
    disappearProgress: number;
    bulbCount: number;
    ballRadiusScale: number;
    claimTime: number;
    temperature: number;
    pushOrigin: Vector3;
    pushNeeded: boolean;
    bulbOrigin: Array<Vector3>;
    bulbTouchTime: Array<number>;
    bulbElapsedTime: number;
    dragPoint: Vector3 | null;
    draggedBall: number | null;
    angle: number;
    sessionProgress: number | null;
    ballCount: number;
    mutagen: number;
    eggPhysics: EggPhysics | null;
    statsAdded: boolean;
    stateCopy: EggState;
}
