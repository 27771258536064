import { useRef } from 'react';
import { useRaceStore } from '../../../../store/raceSlice';

import styles from './Race.module.scss';
import { useRoachStore } from '../../../../store/roachSlice';
import { useRaceSocket } from '../../../../hooks/useRaceSocket';
import Portal from '../../../../components/Portal/Portal';

const Race = () => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const raceData = useRaceStore((state) => state.raceRegisterData);
    const raceFrontUrl = process.env.REACT_APP_RACE_FRONT_URL || '';

    const currentRoach = useRoachStore((state) => state.currentRoach);

    const gameUuid = raceData?.gameUuid || '';

    const { isConnect } = useRaceSocket(gameUuid);

    const handleRaceLoad = () => {
        if (iframeRef && iframeRef.current && iframeRef.current.contentWindow) {
            iframeRef.current.contentWindow.postMessage(
                { type: 'RACE_TOKEN', token: raceData?.token },
                raceFrontUrl,
            );
            console.log('onTokenSend');
        } else {
            console.log('Iframe not found but already loaded');
        }
    };

    return (
        <Portal>
            <div className="w-full h-full">
                {raceData && currentRoach && isConnect && (
                    <iframe
                        ref={iframeRef}
                        className={styles.frame}
                        height={700}
                        onLoad={handleRaceLoad}
                        referrerPolicy="no-referrer"
                        title="race"
                        src={`${raceFrontUrl}/?game=${raceData?.gameUuid}&user=${currentRoach?.id}&raceBackUrl=${raceData?.raceBackUrl}`}
                    />
                )}
            </div>
        </Portal>
    );
};

export default Race;
