import React, { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styles from './Portal.module.scss';

interface PortalProps {
    children: ReactNode;
}

const Portal: FC<PortalProps> = ({ children }) => {
    const portal = document.getElementById('portals') as HTMLDivElement;

    return createPortal(
        <div className={styles.wrapper}>
            <div className={styles.container}>{children}</div>
        </div>,
        portal,
    );
};

export default Portal;
