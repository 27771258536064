import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { Group as TweenGroup } from '@tweenjs/tween.js';
import * as three from 'three';

import { RoachInfoDto } from '../../../api/tor-api';
import { GalleryScene } from './Gallery';

export interface ModelsCache {
    idleAnimation: GLTF;
    chargeSlot: GLTF;
    emptySlot: GLTF;
}

const SUBSTRATE_MESH_COLOR = '#45FF00'
const LIGHT_COLOR = '#FFFFFF';
export const CAMERA_X = 0;

export class RoachesScene {
    private readonly withSubstrate: boolean;
    private requestId: number;
    private root: HTMLElement;

    private tweens: TweenGroup = new TweenGroup();
    private camera!: three.PerspectiveCamera;

    private renderer!: three.WebGLRenderer;
    private controls?: OrbitControls;
    private scene!: three.Scene;

    public clock: three.Clock = new three.Clock();
    public gallery?: GalleryScene;
    public models?: ModelsCache;

    constructor(root: HTMLElement, className?: string, withSubstrate = false) {
        this.withSubstrate = withSubstrate;
        this.root = root;

        this.initRenderer(className);
        this.initScene();
        this.initCamera();

        this.requestId = requestAnimationFrame(this.render);
    }

    destroy = () => {
        cancelAnimationFrame(this.requestId);

        this.renderer.dispose();
        this.renderer.domElement.remove();
    };

    loadEnvMap = (url: string) => {
        new RGBELoader().load(url, (envMap) => {
            // eslint-disable-next-line no-param-reassign
            envMap.mapping = three.EquirectangularReflectionMapping;
            this.scene.environment = envMap;
            envMap.dispose();
        });
    };

    private resizeRendererToDisplaySize = () => {
        const box = this.root.getBoundingClientRect();
        const canvas = this.renderer.domElement;

        if (!box) {
            return false;
        }

        const { width, height } = box;

        const needResize = canvas.width !== width || canvas.height !== height;
        if (needResize) this.renderer.setSize(width, height, false);

        return needResize;
    };

    private render = () => {
        const deltaTime = this.clock.getDelta();

        if (this.resizeRendererToDisplaySize()) {
            const canvas = this.renderer.domElement;
            this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
            this.camera.updateProjectionMatrix();
        }

        this.gallery?.tick(deltaTime)
        this.tweens.update();
        
        this.renderer.render(this.scene, this.camera);
        this.requestId = requestAnimationFrame(this.render);
    };

    // Setup default canvas with light & scene
    private initRenderer = (className?: string) => {
        this.renderer = new three.WebGLRenderer({
            logarithmicDepthBuffer: true,
            antialias: true,
            alpha: true,
        });

        this.renderer.outputColorSpace = three.SRGBColorSpace;

        if (className) this.renderer.domElement.classList.add(className);
        this.root.appendChild(this.renderer.domElement);
    };

    private initScene = () => {
        this.scene = new three.Scene();

        const ambientLight = new three.AmbientLight(LIGHT_COLOR, 0.5);
        this.scene.add(ambientLight);

        const directionalLight = new three.DirectionalLight(LIGHT_COLOR, 1);
        directionalLight.position.set(7, 7, 7);
        directionalLight.target.position.set(0, 0, 0);

        this.scene.add(directionalLight);
        this.scene.add(directionalLight.target);

        if (this.withSubstrate) {
            const geometry = new three.CylinderGeometry(1, 1, 0.25, 20);
            const material = new three.MeshPhongMaterial({ color: SUBSTRATE_MESH_COLOR });
            const mesh = new three.Mesh(geometry, material);
            mesh.position.y = -1 * (mesh.geometry.parameters.height / 2);

            this.scene.add(mesh);
        }

        this.loadEnvMap('roach/GSG_PRO_STUDIOS_METAL_002_sm.hdr');
    };

    private initCamera = () => {
        const canvas = this.renderer.domElement;

        this.camera = new three.PerspectiveCamera(
            47,
            canvas.clientWidth / canvas.clientHeight,
            0.01,
            150,
        );

        // this.initControls();

        this.camera.position.set(CAMERA_X, 0.75, 4.5);
        this.camera.lookAt(0, 0.75, 0);
    };

    private initControls = () => {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.target.set(0, 1, 0);
        this.controls.autoRotate = false;
        this.controls.enableDamping = true;

        this.controls.addEventListener('start', () => {
            if (this.controls) {
                this.controls.autoRotate = false;
            }
        });
    };

    public initModels = async () => {
        const [chargeSlot, emptySlot, idleAnimation] = await Promise.all([
            new GLTFLoader().loadAsync('/roach/slot_charge.glb'),
            new GLTFLoader().loadAsync('/roach/slot_empty.glb'),
            new GLTFLoader().loadAsync('/roach/idle01.glb'),
        ]);

        const models = { chargeSlot, emptySlot, idleAnimation };
        this.models = models;
    };

    public initGallery = async (initialRoaches?: RoachInfoDto[]) => {
        this.gallery = new GalleryScene(
            this.camera,
            this.models as ModelsCache,
            this.tweens,
            this.scene,
        )

        return this.gallery.setup(initialRoaches)
    }
}
