import { TorApi } from './tor-api';

if (!process.env.REACT_APP_API_URL) {
    throw new Error('REACT_APP_API_URL must be defined');
}

export * from './tor-api';
export * from './types';

export const torApi = new TorApi<string>({
    baseURL: process.env.REACT_APP_API_URL,
    secure: true,

    securityWorker: (token) => ({
        headers: {
            Authorization: token ? `Bearer ${token}` : null,
        },
    }),
});
