import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import clsx from 'clsx';

import styles from './Button.module.scss';

import { useRoachStore } from '../../../store/roachSlice';
import { useRaceStore } from '../../../store/raceSlice';

export default function InRaceButton() {
    const race = useRaceStore((state) => state.raceRegisterData);
    const roach = useRoachStore((state) => state.currentRoach);
    const navigate = useNavigate();

    const onClick = useCallback(() => navigate('/race'), [navigate]);

    return (
        <button
            disabled={roach?.id !== race?.roachId}
            className={clsx(styles.button)}
            onClick={onClick}
        >
            {roach?.id !== race?.roachId ? "Go to race" : "Back to race"}
        </button>
    );
}
