import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface FarmingState {
    sessionFarmedCoins: string;
    setSessionFarmedCoins(coins: string): void;
}

export const useFarming = create<FarmingState>()(
    immer((set) => ({
        sessionFarmedCoins: '',
        setSessionFarmedCoins: (coins) => {
            set((state) => {
                if (state.sessionFarmedCoins !== coins) {
                    state.sessionFarmedCoins = coins;
                }
            });
        },
    })),
);
