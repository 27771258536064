import { LaunchParams, retrieveLaunchParams } from '@telegram-apps/sdk-react';
import { isDebugMode } from '../helpers/config';

export function getRawInitData(): LaunchParams {
    if (isDebugMode()) {
        console.log('getRawInitData: isDebugMode == true');

        const debugInitData = process.env.REACT_APP_DEBUG_INIT_DATA;

        if (!debugInitData) {
            throw new Error('getRawInitData: REACT_APP_DEBUG_INIT_DATA must be defined');
        }
        return {
            initDataRaw: debugInitData,
        } as LaunchParams;
    } else {
        console.log('getRawInitData: isDebugMode == false');

        const launchParams = retrieveLaunchParams();

        if (!launchParams) {
            throw new Error('getRawInitData: launchParams not founded');
        }

        return launchParams;
    }
}
