import { useEffect, useState } from 'react';
import { isMobile } from 'mobile-device-detect';
import { useErrorContext } from '../../../store/errorContext';
import { useSDKWrapper } from '../../../helpers/sdkWrapper';
import { RotationPlug } from './RotationPlug';

const Plug: React.FC = () => {
    const [isPortrait, setIsPortrait] = useState(false);
    const { error, setError } = useErrorContext();
    const { openLink } = useSDKWrapper();

    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        if (isMobile) {
            setIsPortrait(window.innerHeight < window.innerWidth);
        }

        window.addEventListener(
            'orientationchange',
            () => {
                // Announce the new orientation number
                if (isMobile) {
                    setIsPortrait(window.innerHeight < window.innerWidth);
                }
            },
            false,
        );

        window.addEventListener(
            'resize',
            () => {
                // Announce the new orientation number
                if (isMobile) {
                    setIsPortrait(window.innerHeight < window.innerWidth);
                }
            },
            false,
        );

        window.addEventListener('online', () => {
            setError(null);
        });
        window.addEventListener('offline', () => {
            setError('You are offline. Please check your internet connection and try again.');
        });

        // eslint-disable-next-line
    }, []);

    return (
        <>
            {!!error && (
                <div className="w-full bg-black h-screen fixed flex items-center justify-center z-[100] flex-col gap-6">
                    <div className="flex flex-col gap-8">
                        <img src="/assets/maintenance.png" alt="maintenance" />
                        <div className="flex flex-col gap-4 px-4 align-center">
                            <span className="font-druk text-2xl font-bold leading-8 text-white text-center">
                                {error}
                            </span>
                            <span className="font-mono text-base text-[#B3B3B3] text-center">
                                Please come back later.
                            </span>
                        </div>
                        <div className="flex gap-8 justify-center">
                            <button
                                onClick={() => {
                                    openLink('https://t.me/roachracingclub');
                                }}
                                className="flex w-9 bg-[#1A1A1A] h-9 rounded-full items-center justify-center border border-[#2B2B2B] bg-[linear-gradient(0deg,_#1A1A1A_0%,_#1A1A1A_100%),_#FFF]"
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14.6667 1.18656L12.3467 14.7231C12.3467 14.7231 12.0221 15.6616 11.1304 15.2115L5.77757 10.4614L5.75275 10.4474C6.47579 9.69596 12.0826 3.86145 12.3276 3.59698C12.7069 3.18738 12.4714 2.94354 12.031 3.25295L3.7491 9.34014L0.553966 8.09589C0.553966 8.09589 0.051146 7.88888 0.00277342 7.43877C-0.0462356 6.98792 0.570515 6.74408 0.570515 6.74408L13.5961 0.830006C13.5961 0.830006 14.6667 0.285598 14.6667 1.18656Z"
                                        fill="white"
                                    />
                                </svg>
                            </button>

                            <a
                                href="https://x.com/roachracingclub"
                                className="flex w-9 bg-[#1A1A1A] h-9 rounded-full items-center justify-center border border-[#2B2B2B] bg-[linear-gradient(0deg,_#1A1A1A_0%,_#1A1A1A_100%),_#FFF]"
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.99386 8.38403L0 2H4.74756L8.44753 5.94577L12.4004 2.01777H15.0151L9.71175 7.29399L16 14H11.2666L7.2603 9.7329L2.98317 13.9882H0.354296L5.99386 8.38403ZM11.9565 12.8172L2.91105 3.18285H4.0571L13.0912 12.8172H11.9565Z"
                                        fill="white"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {isPortrait && <RotationPlug />}
        </>
    );
};

export { Plug };
