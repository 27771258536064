import { useState } from 'react';
import clsx from 'clsx';
import Portal from '../../../components/Portal/Portal';
import styles from './RaceResults.module.scss';
import Button from '../../../components/Button/Button';
import { RoachName } from '../../../components/RoachName/RoachName';
import { useRaceStore } from '../../../store/raceSlice';
import { useRoachStore } from '../../../store/roachSlice';
import { RewardsList } from './RewardsList';
import coin from '../../../assets/mtgn.svg';
import { useStore } from '../../../store/globalStore';

enum Section {
    Highlights = 'HIGHLIGHTS',
    Rewards = 'REWARDS',
}

const RaceResults = () => {
    const [active, setActive] = useState(false);
    const [section, setSection] = useState<Section>(Section.Highlights);
    const raceResult = useRaceStore((state) => state.raceResult);
    const currentRoach = useRoachStore((state) => state.currentRoach);
    const setRaceResult = useRaceStore((state) => state.setRaceResult);
    const setIsShowResult = useRaceStore((state) => state.setIsShowResult);
    const addBalance = useStore((state) => state.addBalance);

    const totalReward = raceResult?.rewards.reduce((total, item) => total + item.reward, 0) || 0;
    const rewards = raceResult?.rewards;

    const handleClick = () => {
        if (section === Section.Highlights) {
            if (rewards && rewards.length < 1) {
                setRaceResult(null);
                setIsShowResult(false);
            } else setSection(Section.Rewards);
        }
        if (section === Section.Rewards) {
            setRaceResult(null);
            setIsShowResult(false);
            addBalance(totalReward);
        }
    };
    return (
        <Portal>
            {currentRoach && (
                <div className={styles.wrapper}>
                    <h1>Race results</h1>
                    <div className={styles.avatarWrapper}>
                        <img
                            className={styles.roachAvatar}
                            src={currentRoach.avatarUrl}
                            alt="roach avatar"
                        />

                        <RoachName name={currentRoach?.name} coin={currentRoach?.coin} />
                    </div>

                    <div className={styles.statsWrapper}>
                        <div className={styles.stat}>
                            <span className={styles.number}>
                                🔋 {raceResult?.totalStatistic.energySpent}
                            </span>
                            Spent
                        </div>
                        <div className={styles.stat}>
                            <span className={styles.number}>{'x2'}</span>
                            Multiplier
                        </div>
                        <div className={styles.stat}>
                            <span className={styles.number}>
                                {raceResult?.totalStatistic.totalRounds}
                            </span>
                            Rounds
                        </div>
                    </div>

                    <div className={styles.highlightsWrapper}>
                        {section === Section.Highlights ? (
                            <>
                                <div
                                    className={clsx(
                                        styles.highlight,
                                        false && styles.personalRecord,
                                    )}
                                >
                                    <span className={styles.desc}>Best Deal</span>
                                    <span className={styles.score}>x9</span>
                                </div>
                                <div
                                    className={clsx(
                                        styles.highlight,
                                        active && styles.personalRecord,
                                    )}
                                    onClick={() => setActive((prev) => !prev)}
                                >
                                    <span className={styles.desc}>Best Round</span>
                                    <span className={styles.time}>1:40:52.57</span>
                                </div>
                            </>
                        ) : (
                            raceResult && <RewardsList rewards={raceResult.rewards} />
                        )}
                    </div>

                    <div className={styles.bottomContainer}>
                        {section === Section.Rewards && (
                            <div className={styles.rewardTotal}>
                                <img src={coin} alt="mtgn" /> <span>{totalReward}</span>
                            </div>
                        )}
                        <Button onClick={handleClick} className={styles.button}>
                            Next
                        </Button>
                    </div>
                </div>
            )}
        </Portal>
    );
};

export default RaceResults;
