import { FC } from 'react';
import styles from './RaceResults.module.scss';
import { RaceRewardDto } from '../../../api/tor-api';
import coin from '../../../assets/mtgn.svg';

interface RewardsListProps {
    rewards: RaceRewardDto[];
}
interface MedalDisplayProps {
    rank: number;
    count: number;
}

const MedalDisplay: React.FC<MedalDisplayProps> = ({ rank, count }) => {
    function getMedalEmoji(rank: number): string | null {
        switch (rank) {
            case 1:
                return '🥇';
            case 2:
                return '🥈';
            case 3:
                return '🥉';
            default:
                return null;
        }
    }
    const medalEmoji = getMedalEmoji(rank);
    const medals = Array(count).fill(medalEmoji);

    return (
        <div className={styles.medals}>
            {medals.map((medal, index) => (
                <span className={styles.medal} key={index}>
                    {medal}
                </span>
            ))}
        </div>
    );
};

const RewardsList: FC<RewardsListProps> = ({ rewards }) => {
    if (rewards.length < 1) return <div>No rewards</div>;

    return (
        <>
            {rewards.map((reward) => (
                <div key={reward.rank} className={styles.highlight}>
                    <MedalDisplay rank={reward.rank} count={reward.count} />
                    <div className={styles.reward}>
                        <img src={coin} alt="mtgn" />
                        <span className={styles.score}>{reward.reward}</span>
                    </div>
                </div>
            ))}
        </>
    );
};

export { RewardsList };
