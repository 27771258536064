import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { RaceRegisterResponseDto, RaceResultDto, torApi } from '../api/tor-api';

interface RaceState {
    raceRegisterData: RaceRegisterResponseDto | null;
    inRace: boolean;
    raceResult: RaceResultDto | null;
    isShowResult: boolean;
    setRaceData(newData: RaceRegisterResponseDto | null): void;
    setRaceResult(result: RaceResultDto | null): void;
    setIsShowResult(input: boolean): void;
    checkCurrentRace: () => void;
}

export const useRaceStore = create<RaceState>()(
    immer((set) => ({
        raceResult: null,
        raceRegisterData: null,
        inRace: false,
        isShowResult: false,
        setRaceData: (newData) => {
            set((state) => {
                if (newData) {
                    state.raceRegisterData = newData;
                    state.inRace = true;
                } else {
                    state.raceRegisterData = null;
                    state.inRace = false;
                }
            });
        },
        setRaceResult: (newResult) => {
            set((state) => {
                if (newResult) {
                    state.raceResult = newResult;
                }
            });
        },
        setIsShowResult: (value) =>
            set((state) => {
                state.isShowResult = value;
            }),
        checkCurrentRace: async () => {
            try {
                const status = (await torApi.api.getApiV1RaceCurrent()).status;
                const currentRaceData = (await torApi.api.getApiV1RaceCurrent()).data;
                console.log('status', status);
                return set((state) => {
                    state.raceRegisterData = status === 200 ? currentRaceData : null;
                    state.inRace = status === 200;
                });
            } catch (error) {
                return set((state) => {
                    state.raceRegisterData = null;
                    state.inRace = false;
                });
            }
        },
    })),
);
