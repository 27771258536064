import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface VideoStore {
    isLoading: boolean;
    loadedVideos: boolean[];
    setVideoLoaded: (index: number) => void;
}

export const useVideoContent = create<VideoStore>()(
    immer((set) => ({
        isLoading: true,
        loadedVideos: [false, false, false],
        setVideoLoaded: (index: number) =>
            set((state) => {
                state.loadedVideos[index] = true;

                const allLoaded = state.loadedVideos.every((loaded) => loaded);

                state.isLoading = !allLoaded;
            }),
    })),
);
