import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { RoachInfoDto, torApi, UpdatedRoachInfoDto } from '../api/tor-api';
import { WritableDraft } from 'immer';

interface RoachState {
    currentRoach: RoachInfoDto | null;
    roaches: RoachInfoDto[] | null;

    setCurrentRoach(roach: RoachInfoDto | null): void;
    setRoach(roach: UpdatedRoachInfoDto): void;
    startCharging(roachId: number): void;
    stopCharging(roachId: number): void;
    setRoaches(roaches: RoachInfoDto[]): void;
    addRoach(roach: RoachInfoDto): void;
}

const setRoach = (updatedRoach: UpdatedRoachInfoDto, state: WritableDraft<RoachState>) => {
    if (!state.roaches) return;

    if (state.currentRoach && state.currentRoach.id === updatedRoach.id) {
        Object.assign(state.currentRoach, updatedRoach);

        if (!updatedRoach.chargeSession) {
            delete state.currentRoach.chargeSession;
        }
    }

    for (const roach of state.roaches) {
        if (roach.id === updatedRoach.id) {
            Object.assign(roach, updatedRoach);

            if (!updatedRoach.chargeSession) {
                delete roach.chargeSession;
            }
        }

        break;
    }
};

export const useRoachStore = create<RoachState>()(
    immer((set) => ({
        currentRoach: null,
        roaches: null,

        setRoach: (updatedRoach) => set((state) => setRoach(updatedRoach, state)),
        addRoach: (roach) =>
            set((state) => {
                if (state.roaches) state.roaches.push(roach);
                else state.roaches = [roach];
            }),
        setRoaches: (roaches) =>
            set((state) => {
                state.roaches = roaches;
            }),
        setCurrentRoach: (roach) =>
            set((state) => {
                state.currentRoach = roach;
            }),
        startCharging: async (roachId) => {
            try {
                const response = await torApi.api.postApiV1ChargeStartRoachId(roachId.toString());
                return set((state) => setRoach(response.data, state));
            } catch (error) {
                return set((state) => {});
            }
        },
        stopCharging: async (roachId) => {
            try {
                const response = await torApi.api.postApiV1ChargeStopRoachId(roachId.toString());
                return set((state) => {
                    setRoach(response.data, state);
                });
            } catch (error) {
                return set((state) => {});
            }
        },
    })),
);
