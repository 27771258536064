import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { DailyRewardInfoDto, TaskItemDto } from '../api/tor-api';

interface TasksState {
    tasks: TaskItemDto[] | null;
    dailyRewards: DailyRewardInfoDto | null;
    setTasks(updatedTasks: TaskItemDto[]): void;
    setDailyRewards(updatedDR: DailyRewardInfoDto): void;
}

export const useTasksStore = create<TasksState>()(
    immer((set) => ({
        tasks: null,
        dailyRewards: null,
        setTasks: (newData: TaskItemDto[]) => {
            set((state) => {
                state.tasks = newData;
            });
        },
        setDailyRewards: (newData: DailyRewardInfoDto) => {
            set((state) => {
                state.dailyRewards = newData;
            });
        },
    })),
);
