import { useState } from 'react';
import clsx from 'clsx';
import styles from './Upgrade.module.scss';
import { ReactComponent as Coin } from '../../assets/mtgn.svg';
import EnergyLimit from './EnergyLimit';
import EnergyGain from './EnergyGain';
import { RoachName } from '../RoachName/RoachName';
import { useRoachStore } from '../../store/roachSlice';
import { useGetUpgrades } from '../../modules/Upgrade/api/upgradeQuery';
import { useStore } from '../../store/globalStore';

enum UpgradeDisplay {
    Select,
    EnergyLimit,
    EnergyGain,
}

const Upgrade = () => {
    const [display, setDisplay] = useState<UpgradeDisplay>(UpgradeDisplay.Select);
    const currentRoach = useRoachStore((state) => state.currentRoach);
    const balance = useStore((state) => state.user?.balance);
    const roachId = currentRoach?.id || 0;
    const { data, isLoading } = useGetUpgrades(roachId);
    const upgradesInfo = data?.data;

    const notAvailable = (value: number): boolean => balance !== undefined && balance.mtgn <= value;

    switch (display) {
        case UpgradeDisplay.Select:
            return (
                <div className={styles.wrapper}>
                    <div className={styles.swipe}>
                        {currentRoach && (
                            <RoachName name={currentRoach.name} coin={currentRoach.coin} />
                        )}
                    </div>
                    <h3 className={styles.title}>Select Upgrade</h3>
                    <div className={styles.container}>
                        {isLoading ? (
                            <div className={styles.skeleton}></div>
                        ) : (
                            upgradesInfo && (
                                <button
                                    className={clsx(
                                        styles.upgButton,
                                        notAvailable(upgradesInfo.energyTankSize.nextUpgradeCost) &&
                                            styles.disabled,
                                    )}
                                    onClick={() => setDisplay(UpgradeDisplay.EnergyLimit)}
                                    disabled={notAvailable(
                                        upgradesInfo.energyTankSize.nextUpgradeCost,
                                    )}
                                >
                                    <div className={styles.right}>
                                        <span className={styles.icon}>🔋</span>
                                        <span className={styles.lvl}>
                                            lvl {upgradesInfo.energyTankSize.upgradesCount + 1}
                                        </span>
                                        <h4>Energy Limit</h4>
                                        <span className={styles.amount}>
                                            {currentRoach?.maxEnergy}
                                            <span
                                                className={styles.additional}
                                            >{`+${upgradesInfo.energyTankSize.upgradeSize}`}</span>
                                        </span>
                                    </div>
                                    <div className={styles.left}>
                                        <Coin className={styles.coin} />
                                        <span>{upgradesInfo.energyTankSize.nextUpgradeCost}</span>
                                    </div>
                                </button>
                            )
                        )}

                        {isLoading ? (
                            <div className={styles.skeleton}></div>
                        ) : (
                            upgradesInfo && (
                                <button
                                    className={clsx(
                                        styles.upgButton,
                                        notAvailable(
                                            upgradesInfo.energyRecoverySpeed.nextUpgradeCost,
                                        ) && styles.disabled,
                                    )}
                                    disabled={notAvailable(
                                        upgradesInfo.energyRecoverySpeed.nextUpgradeCost,
                                    )}
                                    onClick={() => setDisplay(UpgradeDisplay.EnergyGain)}
                                >
                                    <div className={styles.right}>
                                        <span className={styles.icon}>⚡️</span>
                                        <span className={styles.lvl}>
                                            lvl {upgradesInfo.energyRecoverySpeed.upgradesCount + 1}
                                        </span>
                                        <h4>Energy Gain</h4>
                                        <span className={styles.amount}>
                                            {currentRoach?.energyGenerationSpeed}
                                            <span
                                                className={styles.additional}
                                            >{`+${upgradesInfo.energyRecoverySpeed.upgradeSize}`}</span>
                                        </span>
                                    </div>
                                    <div className={styles.left}>
                                        <Coin className={styles.coin} />
                                        <span>
                                            {upgradesInfo.energyRecoverySpeed.nextUpgradeCost}
                                        </span>
                                    </div>
                                </button>
                            )
                        )}
                    </div>
                </div>
            );
        case UpgradeDisplay.EnergyLimit:
            return (
                <div>
                    <button
                        className={styles.back}
                        onClick={() => setDisplay(UpgradeDisplay.Select)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="14"
                            viewBox="0 0 8 14"
                            fill="none"
                        >
                            <path
                                d="M7 1L1.70711 6.29289C1.31658 6.68342 1.31658 7.31658 1.70711 7.70711L7 13"
                                stroke="#B3B3B3"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                        </svg>
                    </button>
                    {upgradesInfo && <EnergyLimit info={upgradesInfo.energyTankSize} />}
                </div>
            );
        case UpgradeDisplay.EnergyGain:
            return (
                <div>
                    <button
                        className={styles.back}
                        onClick={() => setDisplay(UpgradeDisplay.Select)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="14"
                            viewBox="0 0 8 14"
                            fill="none"
                        >
                            <path
                                d="M7 1L1.70711 6.29289C1.31658 6.68342 1.31658 7.31658 1.70711 7.70711L7 13"
                                stroke="#B3B3B3"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                        </svg>
                    </button>
                    {upgradesInfo && <EnergyGain info={upgradesInfo.energyRecoverySpeed} />}
                </div>
            );
        default:
            return null;
    }
};

export default Upgrade;
