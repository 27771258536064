import { FC } from 'react';
import styles from './ChargingStats.module.scss';

interface Props {
    currentEnergy: number;
    energyGenerationSpeed: number;
}

const ChargingStats: FC<Props> = ({ currentEnergy, energyGenerationSpeed }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.segment}>
                <span className={styles.icon}>🔋</span>
                {currentEnergy}
            </div>
            <div className={styles.segment}>
                <span className={styles.icon}>⚡️</span>
                {energyGenerationSpeed} <span>/hour</span>
            </div>
        </div>
    );
};

export default ChargingStats;
