export function isDebugMode() {
    return process.env.REACT_APP_TG_MOCK === 'true';
}
export function isTor() {
    return process.env.REACT_APP_TOR === 'true';
}
export function isErudaConsole() {
    return process.env.REACT_APP_ERUDA_CONSOLE === 'true';
}

export function isPerfStats() {
    return process.env.REACT_APP_PERF_STATS === 'true';
}
