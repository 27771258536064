import React, { useEffect } from 'react';
import styles from './DailyRewards.module.scss';
import Portal from '../../../components/Portal/Portal';
import RewardsList from './RewardsList/RewardsList';
import { useGetTasks } from '../api/tasksQuery';
import { ReactComponent as Coin } from '../../../assets/mtgn.svg';
import { useStore } from '../../../store/globalStore';
import { useTasksStore } from '../../../store/tasksSlice';
import { useNavigate } from 'react-router-dom';
import { DailyRewardItemDto } from '../../../api/tor-api';

const DailyRewards = () => {
    const userBalance = useStore((state) => state.user?.balance.mtgn);
    const setDailyRewards = useTasksStore((state) => state.setDailyRewards);
    const rewardsData = useTasksStore((state) => state.dailyRewards?.rewards);
    const navigate = useNavigate();

    const { data: tasksResponse, isLoading } = useGetTasks();

    function allRewardsNotClaimable(rewards: DailyRewardItemDto[]) {
        return rewards.every((reward) => reward.status !== 'can_claim');
    }

    useEffect(() => {
        if (tasksResponse?.data.dailyReward) {
            setDailyRewards(tasksResponse.data.dailyReward);
            if (tasksResponse.data.dailyReward.rewards) {
            }
        }
    }, [setDailyRewards, tasksResponse]);

    useEffect(() => {
        if (rewardsData && allRewardsNotClaimable(rewardsData)) {
            setTimeout(() => {
                navigate('/home');
            }, 1400);
        }
    }, [navigate, rewardsData]);

    return (
        <Portal>
            <div className={styles.wrapper}>
                <div className={styles.balance}>
                    <Coin className={styles.coin} />
                    <span>{userBalance}</span>
                </div>
                <h1>Daily Reward</h1>
                <p>Claim today’s reward and don’t forget to come back tomorrow!</p>
                <RewardsList isLoading={isLoading} rewards={rewardsData} />
            </div>
        </Portal>
    );
};

export { DailyRewards };
