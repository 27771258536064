import { useCallback } from 'react';
import clsx from 'clsx';

import styles from './Button.module.scss';

import { useRaceRegister } from '../../../pages/Home/api/roachQuery';
import { useRoachStore } from '../../../store/roachSlice';
import { useStore } from '../../../store/globalStore';

export default function RaceButton() {
    const { mutate: raceRegister, isPending: isRegisterPending } = useRaceRegister();
    
    const roach = useRoachStore((state) => state.currentRoach);
    const user = useStore((state) => state.user);

    const onStartRace = useCallback(() => {
        if (roach) {
            raceRegister({ roachId: roach.id, templateId: 'trefoil' })
        }
    }, [roach, raceRegister])
    
    const isAvailable =
        user?.balance !== undefined &&
        user?.nextRoachPriceInfo.cost >= 0 &&
        user?.balance.mtgn >= user?.nextRoachPriceInfo.cost;

    return !isAvailable ? null : (
        <button
            className={clsx(styles.button, isRegisterPending && styles.disabled)}
            disabled={isRegisterPending}
            onClick={onStartRace}
        >
            {!isRegisterPending ? 'Go to Race' : 'Loading Race'}
        </button>
    );
}
