// index: 0 - inner, 1 - outer
import { Color } from 'three';
import { colord, HslColor, RgbColor } from 'colord';

export const mainTheme = {
    components: {
        Button: {
            colorPrimary: '#3EDC03',
            colorPrimaryHover: `rgba(62, 220, 3, 0.9)`,
            colorPrimaryActive: `rgba(62, 220, 3, 0.8)`,
            borderRadius: 9999,
            defaultBg: '#060405',
            defaultBorderColor: '#3EDC03',
            defaultColor: '#3EDC03',
            defaultActiveBg: 'rgba(6, 4, 5, 0.8)',
            defaultHoverBg: 'rgba(6, 4, 5, 0.9)',
        },
    },
};

// inner, outer
const COLORS = [
    [0xff4e19, 0x81d400],
    [0xb2ff84, 0xff7700],
    [0x347c99, 0xff7705],
    [0x618dff, 0xff7919],
    [0x9461ff, 0xf5ff00],
    [0xa600ff, 0xa5ff87],
    [0xffe39e, 0xff739d],
    [0xff84ca, 0x9757ff],
    [0xffb3db, 0xff5769],
    [0x231cbf, 0x00dcff],
    [0xffb3db, 0x9757ff],
    [0xfd83f7, 0xa3ff00],
];

function offsetHSL(c: HslColor, h: number): HslColor {
    const _hslA = { h: c.h, s: c.s, l: c.l };

    _hslA.h = _hslA.h + h;

    return { h: _hslA.h, s: _hslA.s, l: _hslA.l };
}

function changeHue(color: Color, hueChange: number) {
    const color1: RgbColor = { r: color.r * 255, g: color.g * 255, b: color.b * 255 };
    const hslOrig = colord(color1).toHsl();

    const hslChanged = offsetHSL(hslOrig, hueChange);

    const rgb = colord(hslChanged).toRgb();
    const color2 = new Color(rgb.r / 255, rgb.g / 255, rgb.b / 255);
    // console.log('hsl', colorHex, hslOrig, hslChanged, color, color2);

    // console.log('HUE', color, hslOrig, hslChanged, rgb, color2);

    return color2;
}

export function getEggColors(sessionCount: number, index: number, lerpFactor: number) {
    const STEP_COUNT = 12;
    const HUE_CHANGE_PER_STEP = 30;
    const iteration = Math.floor(sessionCount / STEP_COUNT);
    const nextIteration = Math.floor((sessionCount + 1) / STEP_COUNT);
    const step = sessionCount % STEP_COUNT;

    const colors = COLORS;
    const color1index = iteration % colors.length;
    const color2index = nextIteration % colors.length;
    const hueChange = (step + lerpFactor) * HUE_CHANGE_PER_STEP;
    // console.log('hueChange', sessionCount, step, hueChange, color1index, color2index, colors[index][color1index]);

    const color1 = new Color().setHex(colors[color1index][index], 'srgb-linear');
    const color2 = new Color().setHex(colors[color2index][index], 'srgb-linear');
    // if (index === 0) {
    //     color1.r = color1.r * 0.8 + new Color().setHex(colors[color1index][1], 'srgb-linear').r * 0.6;
    //     color1.g = color1.g * 0.8 + new Color().setHex(colors[color1index][1], 'srgb-linear').g * 0.6;
    //     color1.b = color1.b * 0.8 + new Color().setHex(colors[color1index][1], 'srgb-linear').b * 0.6;
    // }
    const color1changed = changeHue(color1, hueChange);
    const color2changed = changeHue(color2, hueChange);

    return new Color().lerpColors(color1changed, color2changed, lerpFactor);
}
