import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { RaceResultDto } from '../api/tor-api';
import { useStore } from '../store/globalStore';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useRaceStore } from '../store/raceSlice';

export const useRaceSocket = (uuid: string) => {
    const socketRef = useRef<Socket>();
    const [isConnect, setIsConnect] = useState(false);
    const token = useStore((state) => state.token);
    const setRaceResult = useRaceStore((state) => state.setRaceResult);
    const setIsShowResult = useRaceStore((state) => state.setIsShowResult);
    const navigate = useNavigate();

    const handleRaceFinished = useCallback(
        (data: RaceResultDto) => {
            setIsConnect(false);
            if (data) {
                navigate('/home');
                setRaceResult(data);
                setIsShowResult(true);
            }
        },
        [navigate, setRaceResult, setIsShowResult],
    );

    useEffect(() => {
        if (uuid) {
            setIsConnect(true);

            socketRef.current = io(`${process.env.REACT_APP_API_URL}/race`, {
                auth: {
                    token: token,
                    raceUuid: uuid,
                },
            });

            socketRef.current.on('race_finished', handleRaceFinished);
            socketRef.current.on('connect_error', (err) => {
                console.log('socket connect error', err);
            });
            socketRef.current.on('error', (err) => {
                console.log('socket error', err);
            });
            socketRef.current.on('disconnect', () => {
                console.log('socket disconnect');
            });

            return () => {
                socketRef.current?.disconnect();
            };
        }
    }, [uuid, token, handleRaceFinished]);

    return { isConnect };
};
