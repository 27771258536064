import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as three from 'three';

import { RoachInfoDto } from '../../../api/tor-api';
import { sleep } from '../../../helpers/utils';
import { EMPTY_ROACH_ID } from './Gallery';
import { ModelsCache } from './Scene';

interface RoachAnimations {
    modelAction?: three.AnimationAction;
    model?: three.AnimationMixer;

    emptySlotAction?: three.AnimationAction;
    emptySlot?: three.AnimationMixer;

    chargeSlotAction?: three.AnimationAction;
    chargeSlot?: three.AnimationMixer;
}

interface RoachModels {
    chargeSlot?: three.Object3D;
    emptySlot?: three.Object3D;
    roach?: three.Object3D;
}

export const X_OFFSET = 2;

export class RoachActor {
    public roach?: RoachInfoDto;
    public idx: number;
    public id: number;

    public animations: RoachAnimations = {};
    public models: RoachModels = {};
    public scene = new three.Group();

    constructor(idx: number, models: ModelsCache, roach?: RoachInfoDto) {
        this.id = roach?.id || EMPTY_ROACH_ID;
        this.roach = roach;
        this.idx = idx;

        this.scene.position.set(this.idx * X_OFFSET, 0, 0);
        this.initSlot(models);
    }

    setup = async (models: ModelsCache) => {
        if (this.roach?.modelUrls.high) {
            const { scene: model } = await new GLTFLoader().loadAsync(this.roach?.modelUrls.high);

            // Setup model animation instance from cache declarations
            const animationMixer = new three.AnimationMixer(model);
            const [clip] = models.idleAnimation.animations;
            const animationAction = animationMixer.clipAction(clip);

            this.animations.modelAction = animationAction;
            this.animations.model = animationMixer;

            this.models.roach = model;
            this.scene.add(this.models.roach);
        }
    };

    set = async (roach: RoachInfoDto, models: ModelsCache) => {
        if (this.roach?.modelUrls.high !== roach.modelUrls.high) {
            if (this.roach) {
                this.animations.modelAction?.reset();
                this.animations.model?.stopAllAction();

                delete this.animations.modelAction;
                delete this.animations.model;

                this.models.roach?.remove();
                delete this.models.roach;
            }

            this.roach = roach;
            this.id = roach.id;

            await this.setup(models);
        } else {
            this.roach = roach;
            this.id = roach.id;
        }
    };

    animation = () => ({
        start: async () => {
            this.animations.modelAction?.play();

            const first_duration = this.animations.emptySlotAction?.getClip().duration || 0;
            this.animations.emptySlotAction?.play();

            await sleep(first_duration);

            if (this.models.emptySlot) {
                this.models.emptySlot.visible = false;
            }

            if (this.models.chargeSlot) {
                this.models.chargeSlot.visible = true;
                this.animations.chargeSlotAction?.play();
            }
        },
        stop: async () => {
            this.animations.modelAction?.stop();
            this.animations.emptySlotAction?.stop();
            this.animations.chargeSlotAction?.stop();
        },
        tick: (deltaTime: number) => {
            this.animations.model?.update(deltaTime);
            this.animations.emptySlot?.update(deltaTime);
            this.animations.chargeSlot?.update(deltaTime);
        },
    });

    private initSlot = (models: ModelsCache) => {
        const prepare = (
            model: GLTF,
            loopStyle: three.AnimationActionLoopStyles,
        ): [three.Object3D, three.AnimationMixer, three.AnimationAction] => {
            const scene = model.scene.clone();
            const mixer = new three.AnimationMixer(scene);

            const [clip] = model.animations;
            const action = mixer.clipAction(clip);

            if (loopStyle !== three.LoopRepeat) {
                action.setLoop(three.LoopRepeat, 1);
            }

            return [scene, mixer, action];
        };

        const [emptySlot, emptySlotMixer, emptySlotAction] = prepare(
            models.emptySlot,
            three.LoopOnce,
        );

        const [chargeSlot, chargeSlotMixer, chargeSlotAction] = prepare(
            models.emptySlot,
            three.LoopRepeat,
        );

        this.animations.emptySlotAction = emptySlotAction;
        this.animations.emptySlot = emptySlotMixer;
        this.models.emptySlot = emptySlot;

        this.animations.chargeSlotAction = chargeSlotAction;
        this.animations.chargeSlot = chargeSlotMixer;
        this.models.chargeSlot = chargeSlot;

        // Charge slot model is hidden by default state
        this.models.chargeSlot.visible = false;

        // Compose
        this.scene.add(this.models.emptySlot);
        this.scene.add(this.models.chargeSlot);
    };
}
